import React from 'react';
import { Table } from 'reactstrap';

const ContentTable = ({ columns, content, render, empty = null }) =>
	content?.length > 0 ? (
		<Table hover responsive className='table-outline table-light table-striped'>
			<thead className='thead-dark'>
				<tr>
					{columns.map(c =>
						typeof c === 'string' ? (
							<th scope='col' key={c}>
								{c}
							</th>
						) : (
							c
						)
					)}
				</tr>
			</thead>
			<tbody>{content.map(c => render(c))}</tbody>
		</Table>
	) : (
		empty
	);

export default ContentTable;
