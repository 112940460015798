import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';
import { getFormattedDate, getLocaleDate } from '../../helpers/activationDateHelpers';
import ActivationsEditModal from './ActivationsEditModal';

const getActivationStatus = ({ iccid, active_at, activable, activation_requested, deleted_at }) => {
	if (deleted_at) {
		return 'Cancelado';
	}
	if (!activable) {
		return 'No Activable';
	}
	if (!iccid && !active_at) {
		return 'Falta Información';
	}
	if (iccid && !active_at) {
		return 'Falta Fecha';
	}
	if (!iccid && active_at) {
		return 'Falta ICCID';
	}
	if (iccid && active_at && !activation_requested) {
		return 'No Activado';
	}
	if (iccid && active_at && activation_requested) {
		return 'Activado';
	}
};

const ActivationsTable = ({ orderItems, isCancelledOrder = false }) => (
	<Table hover responsive className='table-outline mb-0 d-xs-table table-striped'>
		<thead className='thead-dark'>
			<tr>
				<th scope='col' className='align-middle text-center'>
					Orden
				</th>
				{!isCancelledOrder && (
					<th scope='col' className='align-middle'>
						Acciones
					</th>
				)}
				<th scope='col' className='align-middle'>
					Producto
				</th>
				<th scope='col' className='align-middle'>
					ICCID
				</th>
				<th scope='col' className='align-middle'>
					Fecha
				</th>
				<th scope='col' className='align-middle'>
					Activación
				</th>
				<th scope='col' className='align-middle d-none d-md-table-cell'>
					Notas
				</th>
			</tr>
		</thead>
		<tbody>
			{orderItems.map(item => (
				<tr key={item.id}>
					<th scope='row' className='align-middle text-center col-1'>
						<Link to={`/ventas/${item.order_id}`}>{item.order_name || `#${item.order_number}`}</Link>
						{!item.order_name && (
							<span className='small text-muted'>
								{!item.shop_id || item.shop_id === 1 ? 'ES' : 'DE'}
							</span>
						)}
					</th>
					{!isCancelledOrder && (
						<td className='text-center col-1'>
							<ActivationsEditModal buttonLabel='Editar' item={item} />
						</td>
					)}
					<td className='col-3'>
						<div>{item.title}</div>
						<div className='small text-muted'>
							{item.sku} - {item.carrier}
						</div>
					</td>
					<td className='col-2'>
						{item.iccid || '-'}
						{item.phone && <div className='small text-muted'>Teléfono: {item.phone}</div>}
					</td>
					<td className='col-1'>
						{item.activable
							? item.active_at
								? getFormattedDate(getLocaleDate(item.active_at))
								: item.deleted_at
								? 'Cancelado'
								: '-'
							: 'No Activable'}
					</td>
					<td className='col-1'>{getActivationStatus(item)}</td>
					<td className='d-none d-md-table-cell col-2'>
						{item.comments || item.activator_provider_comments
							? `${item.comments} ${item.activator_provider_comments}`
							: '-'}
					</td>
				</tr>
			))}
		</tbody>
	</Table>
);

export default ActivationsTable;
