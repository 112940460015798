import { downloadFile } from './apiProviders';

export const exportProductsToExel = async search => {
	// eslint-disable-next-line
	const { provider, carrier, active_at } = search;
	const url = `${process.env.REACT_APP_API_URL}/providers/${provider}/exportToProvider${
		active_at ? `?date=${active_at}` : ''
	}`;
	downloadFile(url, search, provider);
};
