import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloProvider } from '@apollo/react-hooks';

// Styles
// CoreUI Icons Set
import '@coreui/icons/css/free.min.css';
// Import Flag Icons Set
import 'flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
import 'font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
import 'simple-line-icons/dist/styles/simple-line-icons.css';
// Import Main styles for this application
import './scss/style.scss';

// Containers
import { DefaultLayout } from './containers';

// Pages
import { Login } from './views/Pages';

const client = new ApolloClient({
	link: new HttpLink({
		uri: `${process.env.REACT_APP_API_URL}/graphql`,
		credentials: 'include',
	}),
	cache: new InMemoryCache(),
});

const App = () => (
	<ApolloProvider client={client}>
		<Router>
			<Switch>
				<Route exact path='/login' name='Login Page' component={Login} />
				<Route path='/' name='Home' component={DefaultLayout} />
			</Switch>
		</Router>
	</ApolloProvider>
);

export default App;
