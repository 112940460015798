import React from 'react';
import { ConsultIccid } from 'components/GeneralProviders/ConsultIccid';
import { BulkActivation } from 'components/GeneralProviders/BulkActivation';
import { DisableIccid } from 'components/GeneralProviders/DisableIccid';
import useBundlesProvider from 'hooks/useBundlesProvider';

import { apiProvisionings, apiProviders } from 'constants/globals';

const PROVIDER_CODE = 'spk';

const SparkleView = () => {
	const { dataBundles } = useBundlesProvider(`${apiProviders}/${PROVIDER_CODE}`);
	return (
		<>
			<ConsultIccid BaseURL={apiProvisionings} providerCode={PROVIDER_CODE} />
			<BulkActivation
				dataKey='imsi'
				name='imsi'
				bundles={dataBundles}
				BaseURL={apiProvisionings}
				providerCode={PROVIDER_CODE}
			/>

			<DisableIccid BaseURL={apiProvisionings} providerCode={PROVIDER_CODE} />
		</>
	);
};

export default SparkleView;
