import React from 'react';
import { Nav /* , NavItem, NavLink  */ } from 'reactstrap';
import PropTypes from 'prop-types';
import Gravatar from 'react-gravatar';

import { /* AppAsideToggler, */ AppHeaderDropdown, AppNavbarBrand, AppSidebarToggler } from '@coreui/react';

import UserContext from '../../contexts/UserContext';

const logoSmall =
	'https://img.holafly.com/esim/2020/05/c/r/n/cropped-favicon.png?w=192&h=192&scale.option=fill&cw=192&ch=192&cx=center&cy=center';
const logo =
	'https://img.holafly.com/esim/2020/05/c/r/y/cropped-nuevo-logo-holafly.png?scale.option=fill&scale.width=200&scale.height=54&quality=80';

const propTypes = {
	children: PropTypes.node,
};

const defaultProps = {};

const DefaultHeader = props => {
	// eslint-disable-next-line
	const { children } = props;

	return (
		<React.Fragment>
			<AppSidebarToggler className='d-lg-none' display='md' mobile />
			<AppNavbarBrand
				full={{ src: logo, width: 89, height: 'auto', alt: 'Holafly' }}
				minimized={{ src: logoSmall, width: 30, height: 30, alt: 'Holafly' }}
			/>
			<AppSidebarToggler className='d-md-down-none' display='lg' />
			{/*
            <Nav className='d-md-down-none' navbar>
                <NavItem className='px-3'>
                    <NavLink href='/activaciones/search'>Activaciones</NavLink>
                </NavItem>
                <NavItem className='px-3'>
                    <NavLink href='/productos'>Productos</NavLink>
                </NavItem>
                <NavItem className='px-3'>
                    <NavLink href='/esims'>Esims</NavLink>
                </NavItem>
            </Nav> */}
			<Nav className='ml-auto' navbar>
				{/*
                <NavItem className='d-md-down-none'>
                    <NavLink href='#'>
                        <i className='icon-bell' />
                        <Badge pill color='danger'>
                            5
                        </Badge>
                    </NavLink>
                </NavItem>
                <NavItem className='d-md-down-none'>
                    <NavLink href='#'>
                        <i className='icon-list' />
                    </NavLink>
                </NavItem>
                <NavItem className='d-md-down-none'>
                    <NavLink href='#'>
                        <i className='icon-location-pin' />
                    </NavLink>
                </NavItem>
                */}
				<AppHeaderDropdown direction='down'>
					{/* <DropdownToggle nav> */}
					<UserContext.Consumer>
						{user => (
							<>
								<span className='d-none d-md-inline'>{user.email}</span>
								<Gravatar email={user.email} className='img-avatar ml-2 mr-2' size={35} default='mp' />
							</>
						)}
					</UserContext.Consumer>
					{/* </DropdownToggle> */}
					{/*
                    <DropdownMenu right style={{ right: 'auto' }}>
                        <DropdownItem header tag='div' className='text-center'>
                            <strong>Account</strong>
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-bell-o' /> Updates
                            <Badge color='info'>42</Badge>
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-envelope-o' /> Messages
                            <Badge color='success'>42</Badge>
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-tasks' /> Tasks
                            <Badge color='danger'>42</Badge>
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-comments' /> Comments
                            <Badge color='warning'>42</Badge>
                        </DropdownItem>
                        <DropdownItem header tag='div' className='text-center'>
                            <strong>Settings</strong>
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-user' /> Profile
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-wrench' /> Settings
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-usd' /> Payments
                            <Badge color='secondary'>42</Badge>
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-file' /> Projects
                            <Badge color='primary'>42</Badge>
                        </DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>
                            <i className='fa fa-shield' /> Lock Account
                        </DropdownItem>
                        <DropdownItem>
                            <i className='fa fa-lock' /> Logout
                        </DropdownItem>
                    </DropdownMenu>
                        */}
				</AppHeaderDropdown>
			</Nav>
			{/* <AppAsideToggler className="d-md-down-none" /> */}
			{/* <AppAsideToggler className="d-lg-none" mobile /> */}
		</React.Fragment>
	);
};

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
