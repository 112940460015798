import { useState, useEffect } from 'react';

const packEndpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/threeAustria/getBundles`;

const initialActivationFormValues = {
	iccid: '',
	pack: 0,
};

const usePacksProvider = () => {
	const [packs, setPacks] = useState([{ id: 0, description: 'Selecciona un bundle' }]);
	const [packSelected, setPackSelected] = useState(0);
	const [activationFormValues, setActivationFormValues] = useState(initialActivationFormValues);

	const callApiBundles = async () => {
		try {
			fetch(packEndpoint)
				.then(res => res.json())
				.then(data => {
					const processedPacks = [
						{
							value: '',
							key: 'Selecciona un bundle',
							description: '',
						},
						...data,
					];
					setPacks(processedPacks);
					setPackSelected(processedPacks[0]);
					setActivationFormValues({ ...activationFormValues, pack: processedPacks[0].value });
				});
		} catch (error) {
			console.error('Error fetching packs:', error);
		}
	};

	useEffect(() => {
		callApiBundles();
	}, []);

	return { packs, packSelected, setPackSelected, activationFormValues, setActivationFormValues };
};

export default usePacksProvider;
