import React from 'react';
import { Col, Row } from 'reactstrap';

const LayoutHeader = ({ Component = 'h2', text, actions, className = '', children }) => (
	<Row xs='12' className='align-items-center mb-3 mx-0'>
		<Col className='align-items-center m-0 p-0' xs='12' md={actions?.length > 0 ? 8 : 12}>
			<Component className={`m-0 font-weight-bold ${className}`}>{text || children}</Component>
		</Col>
		{actions && (
			<Col className='text-md-right p-0' xs='12' md='4'>
				{actions}
			</Col>
		)}
	</Row>
);

export default LayoutHeader;
