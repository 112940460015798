import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import LayoutHeader from 'components/LayoutHeader';
import ContentTable from 'components/ContentTable';
import FileDropper from 'views/ChinaMobile/FileDropper';

import { useExcel } from 'hooks/use-excel';

const UPLOADICCIDS_MUTATION = gql`
	mutation UploadIccids($input: [ICCIDInput!]!) {
		UploadIccids(input: $input) {
			uploaded
			created
			updated
		}
	}
`;

export const UploadExcel = ({ onIccidsLoad, dataKey, name }) => {
	const [updated, setUpdated] = useState(false);
	const [hasDuplicateIccid, setHasDuplicateIccid] = useState({ status: false, duplicateIccids: [] });
	const [hasDuplicateData, setHasDuplicateData] = useState({ status: false, duplicateData: [] });
	const { data, setFiles } = useExcel(['iccid', dataKey]);
	const [upload] = useMutation(UPLOADICCIDS_MUTATION, {
		variables: { input: data },
		onCompleted: () => {
			setFiles([]);
			setUpdated(true);
		},
	});

	useEffect(() => {
		if (Array.isArray(data)) {
			const iccids = data.map(e => e.iccid);
			const elementData = data.map(e => e[dataKey]);
			const isDuplicate = iccids.some((item, idx) => iccids.indexOf(item) != idx);
			const isDuplicateData = elementData.some((item, idx) => elementData.indexOf(item) != idx);

			const duplicateIccids = iccids.filter((e, i, a) => a.indexOf(e) !== i); // [2, 4]
			const duplicateData = elementData.filter((e, i, a) => a.indexOf(e) !== i); // [2, 4]

			setHasDuplicateIccid({ duplicateIccids, status: isDuplicate });
			setHasDuplicateData({ duplicateData, status: isDuplicateData });
			onIccidsLoad({ iccids, [name]: elementData });
		}
	}, [data]);

	return (
		<>
			<LayoutHeader
				actions={
					data.length > 1
						? [
								<Button key='0' onClick={() => setFiles([])} className='mr-2'>
									Borrar
								</Button>,
								// <Button disabled={hasDuplicateIccid.status} key='1' onClick={() => upload()}>
								// 	Guardar
								// </Button>,
						  ]
						: null
				}
			></LayoutHeader>
			{hasDuplicateIccid.status && (
				<>
					<div className='alert alert-danger text-center'>
						<p>Cuidado, los siguientes iccids están duplicados</p>
						<ul style={{ display: 'flex', justifyContent: 'center' }}>
							{hasDuplicateIccid.duplicateIccids.map(iccid => (
								<li key={uuidv4()}>{iccid}</li>
							))}
						</ul>
					</div>
				</>
			)}
			{hasDuplicateData.status && (
				<>
					<div className='alert alert-danger text-center'>
						<p>Cuidado, los siguientes {dataKey} están duplicados</p>
						<ul style={{ display: 'flex', justifyContent: 'center' }}>
							{hasDuplicateData.duplicateData.map(element => (
								<li key={uuidv4()}>{element}</li>
							))}
						</ul>
					</div>
				</>
			)}
			{updated && <div className='alert alert-success text-center'>ICCIDs subidos</div>}
			{data.length > 1 ? (
				<ContentTable
					columns={['iccid', dataKey]}
					content={data}
					render={iccid => (
						<tr key={uuidv4()}>
							<td>{iccid.iccid}</td>
							<td>{iccid[dataKey]}</td>
						</tr>
					)}
				/>
			) : (
				<FileDropper setFiles={setFiles} />
			)}
		</>
	);
};
