import React, { useEffect, useState } from 'react';
import LayoutHeader from '../../components/LayoutHeader';
import {
	Alert,
	Card,
	CardBody,
	CardTitle,
	Col,
	Form,
	FormGroup,
	Button,
	Input,
	Row,
	Nav,
	NavItem,
	NavLink,
} from 'reactstrap';
import ThreeAustriaUploader from './ThreeAustriaUploader';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import ContentTable from 'components/ContentTable';
import { el } from 'date-fns/locale';

const packEndpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/threeAustria/getBundles`;

const initialActivationFormValues = {
	iccid: '',
	pack: 0,
};

const initialActivationBulkFormValues = {
	iccids: [],
	msisdns: [],
	pack: 0,
	bagName: '',
};

const initialDeleteFormValues = {
	iccid: '',
	pack: 0,
};

const initialConsultFormValues = {
	iccid: '',
};
const setSpeedIccid = {
	iccid: '',
};

const initialBundleInfoFormValues = {
	iccid: '',
};

const initialResp = {
	activation: {
		type: 'danger',
		message: '',
	},
	delete: {
		type: 'danger',
		message: '',
	},
	speed: {
		type: 'warning',
		message: 'Processing',
	},
};

const speeds = [
	{
		label: 'Selecciona una velocidad',
		value: '',
	},
	{
		label: '64 Kbps',
		value: '64000',
	},
	{
		label: '128 Kbps',
		value: '128000',
	},
	{
		label: '256 Kbps',
		value: '256000',
	},
	{
		label: '512 Kbps',
		value: '512000',
	},
	{
		label: '768 Kbps',
		value: '768000',
	},
	{
		label: '1 Mbps',
		value: '1024000',
	},
	{
		label: '1.5 Mbps',
		value: '1500000',
	},
	{
		label: '2 Mbps',
		value: '2000000',
	},
	{
		label: '3 Mbps',
		value: '3000000',
	},
	{
		label: '4 Mbps',
		value: '4000000',
	},
	{
		label: '5 Mbps',
		value: '5000000',
	},
	{
		label: '8 Mbps',
		value: '8000000',
	},
	{
		label: '16 Mbps',
		value: '16000000',
	},
	{
		label: '25 Mbps',
		value: '25000000',
	},
	{
		label: '32 Mbps',
		value: '32000000',
	},
];

const ThreeAustria = () => {
	const [packs, setPacks] = useState([]);
	const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
	const [consultBtnIsDisabled, setConsultBtnIsDisabled] = useState(false);
	const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(false);
	const [saveBulkBtnIsDisabled, setSaveBulkBtnIsDisabled] = useState(false);
	const [packBulkSelected, setPackBulkSelected] = useState(0);
	const [activationBulkFormValues, setActivationBulkFormValues] = useState(initialActivationBulkFormValues);
	const [isActivationBulkAlertDisplaying, setIsActivationBulkAlertDisplaying] = useState(false);

	const [dataInfo, setDataInfo] = useState({});
	const [bundleInfo, setBundleInfo] = useState({});
	const [packSelected, setPackSelected] = useState(0);
	const [packDeleteSelected, setPackDeleteSelected] = useState(0);

	const [cardSpeed, setCardSpeed] = useState('');
	const [cardSpeedSelected, setCardSpeedSelected] = useState('');
	const [speedDataInfo, setSpeedDataInfo] = useState({});

	const [activationFormValues, setActivationFormValues] = useState(initialActivationFormValues);
	const [bundleInfoFormValues, setBundleInfoFormValues] = useState(initialBundleInfoFormValues);
	const [consultFormValues, setConsultFormValues] = useState(initialConsultFormValues);
	const [deleteFormValues, setDeleteFormValues] = useState(initialDeleteFormValues);
	const [isActivationAlertDisplaying, setIsActivationAlertDisplaying] = useState(false);
	const [isBundleInfoAlertDisplaying, setIsBundleInfoAlertDisplaying] = useState(false);
	const [isConsultAlertDisplaying, setIsConsultAlertDisplaying] = useState(false);
	const [isSetSpeedAlertDisplaying, setIsSetSpeedAlertDisplaying] = useState(false);
	const [isDeleteAlertDisplaying, setIsDeleteAlertDisplaying] = useState(false);
	const [resp, setResp] = useState(initialResp);
	const [hasDuplicateIccid, setHasDuplicateIccid] = useState(false);
	const [hasDuplicateMsisdn, setHasDuplicateMsisdn] = useState(false);
	const [isServerBusy, setIsServerBusy] = useState({ status: false, message: '' });
	const [bulkConsultData, setBulkConsultData] = useState([]);
	const [isErrorTabActive, setIsErrorTabActive] = useState(false);

	useEffect(() => {
		fetch(packEndpoint)
			.then(res => res.json())
			.then(data => {
				const processedPacks = [
					{
						value: '',
						key: 'Selecciona un bundle',
						description: '',
					},
					...data,
				];
				setPacks(processedPacks);
				setPackSelected(processedPacks[0]);
				setActivationFormValues({ ...activationFormValues, pack: processedPacks[0].value });
				setActivationBulkFormValues({ ...activationBulkFormValues, pack: processedPacks[0].value });
				setDeleteFormValues({ ...activationFormValues, pack: processedPacks[0].value });
			});

		checkBulkStatus();
	}, []);

	const showActivationAlert = () => {
		setIsActivationAlertDisplaying(true);
		setTimeout(() => setIsActivationAlertDisplaying(false), 7000);
	};

	const showBundleInfoAlert = () => {
		setIsBundleInfoAlertDisplaying(true);
		setTimeout(() => setIsBundleInfoAlertDisplaying(false), 7000);
	};

	const showActivationBulkAlert = () => {
		setIsActivationBulkAlertDisplaying(true);
		setTimeout(() => setIsActivationBulkAlertDisplaying(false), 7000);
	};

	const showConsultAlert = () => {
		setIsConsultAlertDisplaying(true);
		setTimeout(() => setIsConsultAlertDisplaying(false), 7000);
	};

	const showSetSpeedAlert = () => {
		setIsSetSpeedAlertDisplaying(true);
		setTimeout(() => setIsSetSpeedAlertDisplaying(false), 7000);
	};

	const showDeleteAlert = () => {
		setIsDeleteAlertDisplaying(true);
		setTimeout(() => setIsDeleteAlertDisplaying(false), 7000);
	};

	const onActivationChange = e => {
		if (e.target.name === 'pack') {
			setPackSelected(e.target.value);
		}
		setActivationFormValues({ ...activationFormValues, [e.target.name]: e.target.value });
	};

	const onDeleteChange = e => {
		if (e.target.name === 'pack') {
			setPackDeleteSelected(e.target.value);
		}
		setDeleteFormValues({ ...deleteFormValues, [e.target.name]: e.target.value });
	};

	const onActivationBulkChange = e => {
		if (e.target.name === 'pack') {
			setPackBulkSelected(e.target.value);
		}
		setActivationBulkFormValues({ ...activationBulkFormValues, [e.target.name]: e.target.value });
	};

	const onIccidsLoad = ({ iccids, msisdns }) => {
		console.log({ iccids, msisdns });
		if (Array.isArray(iccids) && Array.isArray(msisdns)) {
			const isDuplicate = iccids.some((item, idx) => iccids.indexOf(item) != idx);
			const isMsisndDuplicate = msisdns.some((item, idx) => msisdns.indexOf(item) != idx);
			setHasDuplicateIccid(isDuplicate);
			setHasDuplicateMsisdn(isMsisndDuplicate);
			setActivationBulkFormValues({ ...activationBulkFormValues, iccids, msisdns });
		}
	};

	const onConsultChange = e => {
		setConsultFormValues({ ...consultFormValues, [e.target.name]: e.target.value });
	};

	const onSpeedSelected = e => {
		if (e.target.name === 'speed') {
			setCardSpeedSelected(e.target.value);
			setCardSpeed({ label: cardSpeedSelected, value: e.target.value });
		}
	};

	const onBundleInfoChange = e => {
		setBundleInfoFormValues({ ...bundleInfoFormValues, [e.target.name]: e.target.value });
	};

	const onBulkClick = () => {
		setBulkConsultData([]);
		setSaveBulkBtnIsDisabled(true);

		const { iccids, pack, msisdns, bagName } = activationBulkFormValues;
		const endpoint = `${process.env.REACT_APP_AUTOMATIZATIONS_API_URL}/threeAustria/addBundleBulk`;
		fetch(endpoint, {
			method: 'POST',
			body: JSON.stringify({
				bundleId: pack,
				iccids,
				msisdns,
				bagName,
			}),
		})
			.then(res =>
				res.json().then(data => {
					setResp({
						...resp,
						activation: {
							type: res.status !== 200 ? 'danger' : 'success',
							message: data.message,
						},
					});

					const actions = {
						BUSY: () => setIsServerBusy({ status: true, message: data?.message }),
						DEFAULT: () => setIsServerBusy({ status: true, message: data?.message }),
						OK: () => setIsServerBusy({ status: false, message: data?.message }),
					};

					actions[data?.status] ? actions[data?.status]() : actions['DEFAULT']();

					showActivationBulkAlert();
					setSaveBulkBtnIsDisabled(false);
				})
			)
			.catch(error => {
				setResp({
					...resp,
					activation: {
						type: 'danger',
						message: error.message,
					},
				});
				showActivationBulkAlert();
				setIsActivationBulkAlertDisplaying(false);
			});
	};

	const checkBulkStatus = async () => {
		const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/threeAustria/getBulkStatus`;
		const response = await fetch(endpoint);
		const data = await response.json();
		console.log(data);

		const actions = {
			BUSY: () => setIsServerBusy({ status: true, message: data?.message }),
			DEFAULT: () => setIsServerBusy({ status: true, message: data?.message }),
			OK: () => setIsServerBusy({ status: false, message: data?.message }),
		};

		actions[data?.status] ? actions[data?.status]() : actions['DEFAULT']();
		console.log([data?.status]);
	};

	const onBulkConsultClick = async () => {
		console.log('bulk Consult');

		console.log('bulk', bagName);
		setSaveBulkBtnIsDisabled(true);

		// setSaveBulkBtnIsDisabled(true);
		const { iccids, pack, msisdns, bagName } = activationBulkFormValues;
		const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/threeAustria/checkBundleBulk/${bagName}`;
		console.log(endpoint);
		const response = await fetch(endpoint);
		const data = await response.json();
		if (data?.iccids) {
			setBulkConsultData(data?.iccids);
		}
		if (data?.message) {
			setBulkConsultData(data?.message);
		}
		setSaveBulkBtnIsDisabled(false);
		const actions = {
			BUSY: () => setIsServerBusy({ status: true, message: data?.message }),
			DEFAULT: () => setIsServerBusy({ status: true, message: data?.message }),
			OK: () => setIsServerBusy({ status: false, message: data?.message }),
		};

		actions[data?.status] ? actions[data?.status]() : actions['DEFAULT']();
		console.log(data);
	};

	const onBulkSyncProviderClick = async () => {
		console.log('bulk Sync');
		const { bagName } = activationBulkFormValues;
		const endpoint = `${process.env.REACT_APP_AUTOMATIZATIONS_API_URL}/threeAustria/updateBundleBulk/${bagName}`;
		console.log(endpoint);
		const response = await fetch(endpoint);
		const data = await response.json();
		console.log(data);
		const actions = {
			BUSY: () => setIsServerBusy({ status: true, message: data?.message }),
			DEFAULT: () => setIsServerBusy({ status: true, message: data?.message }),
			OK: () => setIsServerBusy({ status: false, message: data?.message }),
		};

		actions[data?.status] ? actions[data?.status]() : actions['DEFAULT']();
		setSaveBulkBtnIsDisabled(false);
	};

	const onBulkReloadIccidsWithErrorsClick = () => {
		console.log('bulk Reload');
	};

	return (
		<>
			<LayoutHeader>Three Austria</LayoutHeader>
			{isServerBusy?.status && isServerBusy.status !== 'OK' && (
				<Alert color='warning' className='text-center animated fadeDown'>
					<p>{isServerBusy?.message}</p>
				</Alert>
			)}

			<Card>
				<CardBody>
					<CardTitle tag='h5'>Activación y Top up</CardTitle>
					<Form
						onSubmit={e => {
							setSaveBtnIsDisabled(true);
							const { iccid, pack } = activationFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/threeAustria/addBundle/${iccid}/${pack}`;
							fetch(endpoint)
								.then(res =>
									res.json().then(data => {
										setResp({
											...resp,
											activation: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: data.message,
											},
										});
										showActivationAlert();
										setSaveBtnIsDisabled(false);
									})
								)
								.catch(error => {
									setResp({
										...resp,
										activation: {
											type: 'danger',
											message: error.message,
										},
									});
									showActivationAlert();
									setIsActivationAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input onChange={onActivationChange} name='iccid' placeholder='# Iccid' />
								</FormGroup>
							</Col>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input type='select' value={packSelected} name='pack' onChange={onActivationChange}>
										{Array.isArray(packs) &&
											packs?.map((item, idx) => (
												<option value={item.value} key={idx}>
													{item.key}
												</option>
											))}
									</Input>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button
										color='info'
										disabled={
											saveBtnIsDisabled ||
											!activationFormValues.iccid ||
											!activationFormValues.pack
										}
										title='Submit'
									>
										Activar y Top up
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{isActivationAlertDisplaying && (
						<Alert color={resp.activation.type} className='text-center animated fadeDown'>
							{JSON.stringify(resp.activation.message)}
						</Alert>
					)}
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag='h5'>Consultar</CardTitle>
					<Form
						onSubmit={e => {
							setConsultBtnIsDisabled(true);
							const { iccid } = consultFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/threeAustria/getConsumedData/${iccid}`;
							fetch(endpoint)
								.then(res =>
									res.json().then(data => {
										setDataInfo(data);
										setResp({
											...resp,
											delete: {
												type: res.status !== 200 ? 'danger' : 'success',
												message:
													res.status !== 200
														? data.message
														: `${iccid} desactive process: ${data.message}`,
											},
										});
										if (res.status !== 200) {
											showConsultAlert();
										}
										setConsultBtnIsDisabled(false);
									})
								)
								.catch(error => {
									setResp({
										...resp,
										delete: {
											type: 'danger',
											message: error.message,
										},
									});
									showConsultAlert();
									setIsConsultAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input onChange={onConsultChange} name='iccid' placeholder='# Iccid' />
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button
										color='info'
										disabled={consultBtnIsDisabled || !consultFormValues.iccid}
										title='Submit'
									>
										Consultar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{Array.isArray(dataInfo?.bundles) &&
						dataInfo?.bundles?.map((bundle, index) => (
							<div>
								<h6>Bundle: {bundle.bundleName}</h6>
								<ul>
									<li>
										<h6>{bundle?.remainingDataMb?.toFixed(2)} Mb restantes</h6>
									</li>
									<li>
										<h6>{bundle?.usedDataPercent?.toFixed(2)}% de datos usados</h6>
									</li>
									{bundle?.activationDate && bundle?.expirationDate && (
										<ul>
											{bundle?.activationDate && (
												<li>
													Fecha de activación:{' '}
													{format(new Date(bundle?.activationDate), 'yyyy-MM-dd')}
												</li>
											)}
											{bundle?.expirationDate && (
												<li>
													Fecha de expiración:{' '}
													{format(new Date(bundle?.expirationDate), 'yyyy-MM-dd')}
												</li>
											)}
										</ul>
									)}
								</ul>
							</div>
						))}
					{isConsultAlertDisplaying && (
						<Alert color={resp.delete.type} className='text-center animated fadeDown'>
							{JSON.stringify(resp.delete.message)}
						</Alert>
					)}
				</CardBody>
			</Card>
			<Card>
				<CardBody>
					<CardTitle tag='h5'>Cambiar velocidad</CardTitle>
					<Form
						onSubmit={e => {
							setConsultBtnIsDisabled(true);
							const { iccid } = setSpeedIccid;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/threeAustria/setSpeed/${iccid}/${cardSpeedSelected}`;
							fetch(endpoint)
								.then(res =>
									res.json().then(data => {
										setSpeedDataInfo(data);
										console.log(
											'%cThreeAustria.js line:567 object',
											'color: #009900;',
											`${data.message}`
										);
										setResp({
											...resp,
											speed: {
												type: 'success',
												message: `${data.message}`,
											},
										});
									})
								)
								.catch(error => {
									console.log('%cThreeAustria.js line:576 object', 'color: #990099;', error);
									setResp({
										...resp,
										speed: {
											type: 'danger',
											message: `Error al cambiar la velocidad en el ICCID: ${iccid}`,
										},
									});
								});
							showSetSpeedAlert();
							setIsConsultAlertDisplaying(false);
						}}
					>
						<Row>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										name='iccid'
										placeholder='# Iccid'
										onChange={e => (setSpeedIccid.iccid = e.target.value)}
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										type='select'
										value={cardSpeedSelected}
										placeholder='Selecciona una velocidad'
										name='speed'
										onChange={e => {
											onSpeedSelected(e);
										}}
									>
										{speeds.map((item, idx) => (
											<option value={item.value} key={idx}>
												{item.label}
											</option>
										))}
									</Input>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button
										color='info'
										disabled={consultBtnIsDisabled || !setSpeedIccid.iccid}
										title='Submit'
									>
										Cambiar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{isSetSpeedAlertDisplaying && (
						<Alert color={resp.speed.type} className='text-center animated fadeDown'>
							<p>{JSON.stringify(resp.speed.message)}</p>
						</Alert>
					)}
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag='h5'>Activación Bulk</CardTitle>
					<Form
					// onSubmit={e => {
					// 	setSaveBulkBtnIsDisabled(true);
					// 	const { iccids, pack, msisdns } = activationBulkFormValues;
					// 	e.preventDefault();
					// 	console.log({ iccids, pack, msisdns })
					// 	const endpoint = `${process.env.REACT_APP_AUTOMATIZATIONS_API_URL}/threeAustria/addBundleBulk`;
					// 	fetch(endpoint, {
					// 		method: 'POST',
					// 		body: JSON.stringify({
					// 			bundleId: pack,
					// 			iccids,
					// 			msisdns
					// 		})
					// 	})
					// 		.then(res => res.json()
					// 			.then(data => {
					// 				setResp({
					// 					...resp,
					// 					activation: {
					// 						type: res.status !== 200 ? 'danger' : 'success',
					// 						message: data.message
					// 					}
					// 				});
					// 				showActivationBulkAlert();
					// 				setSaveBulkBtnIsDisabled(false);
					// 			}))
					// 		.catch(error => {
					// 			setResp({
					// 				...resp,
					// 				activation: {
					// 					type: 'danger',
					// 					message: error.message
					// 				}
					// 			});
					// 			showActivationBulkAlert();
					// 			setIsActivationBulkAlertDisplaying(false);
					// 		});
					// }}
					>
						<Row>
							{/* <Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationBulkChange}
										name='iccidStart'
										placeholder='# Iccid de inicio'
									/>
								</FormGroup>
							</Col>*/}
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										onChange={onActivationBulkChange}
										name='bagName'
										placeholder='# Nombre de la bolsa'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										type='select'
										value={packBulkSelected}
										name='pack'
										onChange={onActivationBulkChange}
									>
										{Array.isArray(packs) &&
											packs?.map((item, idx) => (
												<option value={item.value} key={idx}>
													{item.key}
												</option>
											))}
									</Input>
								</FormGroup>
							</Col>
							{/* <Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationBulkChange}
										type="email"
										name='email'
										placeholder='# Email'
									/>
								</FormGroup>
							</Col> */}
							<Col xs={12} md={1}>
								<FormGroup>
									<Button
										color='info'
										disabled={
											saveBulkBtnIsDisabled ||
											activationBulkFormValues === undefined ||
											activationBulkFormValues?.iccids?.length === 0 ||
											!activationBulkFormValues?.msisdns ||
											hasDuplicateIccid ||
											hasDuplicateMsisdn ||
											!activationBulkFormValues.pack ||
											!activationBulkFormValues.bagName
										}
										title='Submit'
										onClick={onBulkClick}
									>
										Bulk
									</Button>
								</FormGroup>
							</Col>
							<Col xs={12} md={1}>
								<FormGroup>
									<Button
										color='info'
										disabled={
											saveBulkBtnIsDisabled || !activationBulkFormValues.bagName

											// saveBulkBtnIsDisabled ||
											// activationBulkFormValues === undefined ||
											// activationBulkFormValues?.iccids?.length === 0 ||
											// !activationBulkFormValues?.msisdns ||
											// hasDuplicateIccid ||
											// hasDuplicateMsisdn ||
											// !activationBulkFormValues.pack
										}
										title='Submit'
										onClick={onBulkConsultClick}
									>
										Consultar
									</Button>
								</FormGroup>
							</Col>
							<Col xs={12} md={4}>
								<FormGroup>
									<Button
										color='info'
										disabled={
											saveBulkBtnIsDisabled ||
											// activationBulkFormValues === undefined ||
											// activationBulkFormValues?.iccids?.length === 0 ||
											// !activationBulkFormValues?.msisdns ||
											// hasDuplicateIccid ||
											// hasDuplicateMsisdn ||
											// !activationBulkFormValues.pack
											!activationBulkFormValues.bagName
										}
										title='Submit'
										onClick={onBulkSyncProviderClick}
									>
										Sincronizar consulta proveedor
									</Button>
								</FormGroup>
							</Col>
							{/* <Col xs={12} md={4}>
								<FormGroup>
									<Button color='info' disabled={
										false
										// saveBulkBtnIsDisabled ||
										// activationBulkFormValues === undefined ||
										// activationBulkFormValues?.iccids?.length === 0 ||
										// !activationBulkFormValues?.msisdns ||
										// hasDuplicateIccid ||
										// hasDuplicateMsisdn ||
										// !activationBulkFormValues.pack
									}
										title='Submit'
										onClick={onBulkReloadIccidsWithErrorsClick}
									>
										Recargar iccids con error
									</Button>
								</FormGroup>
							</Col> */}
						</Row>
						{!Array.isArray(bulkConsultData) && bulkConsultData && (
							<Alert color='danger' className='text-center animated fadeDown'>
								<p>{bulkConsultData}</p>
							</Alert>
						)}
						{Array.isArray(bulkConsultData) && bulkConsultData.length > 0 && (
							<>
								<Nav tabs>
									<NavItem>
										<NavLink
											href='#'
											className={!isErrorTabActive ? 'active' : ''}
											onClick={() => setIsErrorTabActive(false)}
										>
											Lista Completa
										</NavLink>
									</NavItem>
									<NavItem>
										<NavLink
											href='#'
											className={isErrorTabActive ? 'active' : ''}
											onClick={() => setIsErrorTabActive(true)}
										>
											Errores
										</NavLink>
									</NavItem>
								</Nav>
								<ContentTable
									columns={['iccid', 'bundle', 'status']}
									content={
										isErrorTabActive
											? bulkConsultData.filter(e => e.status === 'ERROR')
											: bulkConsultData
									}
									render={iccid => (
										<tr key={uuidv4()}>
											<td>{iccid.iccid}</td>
											<td>{iccid.bundleName}</td>
											<td>{iccid.status}</td>
										</tr>
									)}
								/>
							</>
						)}
						<Alert color='warning' className='text-center animated fadeDown'>
							<p>El excel debe "solo" contener las siguientes columnas en el siguiente orden</p>
							<p>| iccid | msisdn |</p>
						</Alert>
						<ThreeAustriaUploader onIccidsLoad={onIccidsLoad} />
					</Form>
					{isActivationBulkAlertDisplaying && (
						<Alert color={resp.activation.type} className='text-center animated fadeDown'>
							{JSON.stringify(resp.activation.message)}
						</Alert>
					)}
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag='h5'>Desactivar</CardTitle>
					<Form
						onSubmit={e => {
							setDeleteBtnIsDisabled(true);
							const { iccid, pack } = deleteFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/threeAustria/deleteBundle/${iccid}/${pack}`;
							fetch(endpoint)
								.then(res =>
									res.json().then(data => {
										setResp({
											...resp,
											delete: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: data.message,
											},
										});
										showDeleteAlert();
										setDeleteBtnIsDisabled(false);
									})
								)
								.catch(error => {
									setResp({
										...resp,
										delete: {
											type: 'danger',
											message: error.message,
										},
									});
									showDeleteAlert();
									setIsDeleteAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input onChange={onDeleteChange} name='iccid' placeholder='# Iccid' />
								</FormGroup>
							</Col>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										type='select'
										value={packDeleteSelected}
										name='pack'
										onChange={onDeleteChange}
									>
										{Array.isArray(packs) &&
											packs?.map((item, idx) => (
												<option value={item.value} key={idx}>
													{item.key}
												</option>
											))}
									</Input>
								</FormGroup>
							</Col>
							<Col xs={12} md={4}>
								<FormGroup>
									<Button
										color='danger'
										disabled={
											deleteBtnIsDisabled || !deleteFormValues.iccid || !deleteFormValues.pack
										}
										title='Submit'
									>
										Desactivar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{isDeleteAlertDisplaying && (
						<Alert color={resp.delete.type} className='text-center animated fadeDown'>
							{JSON.stringify(resp.delete.message)}
						</Alert>
					)}
				</CardBody>
			</Card>

			{/* <Card>
				<CardBody>
					<CardTitle tag="h5">
						Consultar información bundle
					</CardTitle>
					<Form
						onSubmit={e => {
							setBundleInfoBtnIsDisabled(true);
							const { iccid } = bundleInfoFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/chinamobile/getBundleInfo/${iccid}`;
							fetch(endpoint)
								.then(res => res.json()
									.then(data => {
										setBundleInfo(data);
										setResp({
											...resp,
											delete: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: res.status !== 200 ? data.message : `${iccid} desactive process: ${data.message}`
											}
										});
										if(res.status !== 200) {
											showBundleInfoAlert();
										}
										setBundleInfoBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										delete: {
											type: 'danger',
											message: error.message
										}
									});
									showBundleInfoAlert();
									setIsBundleInfoAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onBundleInfoChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='info' disabled={bundleInfoBtnIsDisabled || !bundleInfoFormValues.iccid} title='Submit' >
										Consultar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{Array.isArray(bundleInfo.bundles) && bundleInfo.bundles.map(bundle => <>
						<h6>Name: {bundle.bundleName}</h6>
						<ul>
							<li><h6>Create Time: {bundle.bundleCreateTime}</h6></li>
							<li><h6>Description: {bundle.bundleDescription}</h6></li>
							<li><h6>End Time: {bundle.bundleEndTime}</h6></li>
							<li><h6>Expire Time: {bundle.bundleExpireTime}</h6></li>
						</ul>
					</>)
					}
					{isBundleInfoAlertDisplaying && <Alert color={resp.delete.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.delete.message)}
					</Alert>

					}
				</CardBody>
			</Card> */}
		</>
	);
};

export default ThreeAustria;
