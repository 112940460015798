const MESSAGE_ERROR_CATCH = 'Ha ocurrido un error, intenta nuevamente';

const headers = { 'Content-Type': 'application/json' };

export const downloadFile = (urlToSend, body, name) => {
	const req = new XMLHttpRequest();
	req.open('POST', urlToSend, true);
	req.responseType = 'blob';
	const today = new Date().toISOString().slice(0, 10);
	// eslint-disable-next-line
	req.onload = event => {
		const blob = req.response;
		const fileName = req.getResponseHeader('Content-Disposition');
		const link = document.createElement('a');
		const href = URL.createObjectURL(blob);
		link.href = href;
		link.download = fileName || `${name}_${today}`;
		link.click();
		URL.revokeObjectURL(href);
		link.remove();
	};

	req.send(JSON.stringify({ search: body }));
};

export const getBundlesProviders = async URL => {
	try {
		const resp = await fetch(URL);

		if (resp.status === 200) {
			const result = await resp.json();
			return { data: result };
		}

		return { data: [] };
	} catch (error) {
		return { data: [] };
	}
};

export const postBulk = async (BaseURL, providerCode, body) => {
	try {
		const resp = await fetch(`${BaseURL}/${providerCode}/bulk`, {
			method: 'POST',
			headers,
			body: JSON.stringify(body),
		});

		const result = await resp.json();

		console.log(result);

		if (resp.status === 200) {
			return {
				message: result.data.message,
				type: 'success',
			};
		}

		return {
			message: result.data.error,
			type: 'danger',
		};
	} catch (error) {
		return {
			message: MESSAGE_ERROR_CATCH,
			type: 'danger',
		};
	}
};

export const getConsultIccid = async (BaseURL, iccid) => {
	try {
		const resp = await fetch(`${BaseURL}/${iccid}`, {
			method: 'GET',
			headers,
		});

		if (resp.status === 200) {
			const result = await resp.json();
			const { iccid } = result.data.data;
			return {
				data: result.data.data,
				message: `El ICCID ${iccid} ha sido consultado satisfactoriamente`,
				type: 'success',
			};
		}

		const { error } = await resp.json();
		return {
			data: {},
			message: error,
			type: 'danger',
		};
	} catch (error) {
		return {
			data: {},
			message: MESSAGE_ERROR_CATCH,
			type: 'danger',
		};
	}
};

export const deleteIccid = async (BaseURL, iccid) => {
	try {
		const resp = await fetch(`${BaseURL}/${iccid}`, {
			method: 'PUT',
			headers,
			body: JSON.stringify({ action: 'SUSPEND' }),
		});

		const result = await resp.json();

		console.log(result);

		if (resp.status === 200) {
			return {
				message: `El ICCID ${result.orderItem[0].product.reliesOnProduct.iccid} ha sido desactivado satisfactoriamente`,
				type: 'success',
			};
		}

		return {
			message: result.error,
			type: 'danger',
		};
	} catch (error) {
		return {
			message: MESSAGE_ERROR_CATCH,
			type: 'danger',
		};
	}
};

export const postActivationTopup = async (BaseURL, providerCode, iccid, bundleId) => {
	try {
		const resp = await fetch(`${BaseURL}/${providerCode}/${iccid}`, {
			method: 'PUT',
			headers,
			body: JSON.stringify({ action: 'ACTIVATE', data: { bundleId } }),
		});

		const result = await resp.json();

		if (resp.status === 200) {
			return {
				type: 'success',
				message: result.message,
			};
		}

		return {
			type: 'danger',
			message: result.error,
		};
	} catch (error) {
		return {
			type: 'danger',
			message: error.message || MESSAGE_ERROR_CATCH,
		};
	}
};
