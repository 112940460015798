export const getDate = argument => {
	const argStr = Object.prototype.toString.call(argument);
	if (argument instanceof Date || (typeof argument === 'object' && argStr === '[object Date]')) {
		// Prevent the date to lose the milliseconds when passed to new Date() in IE10
		return new Date(argument.getTime());
	} else if (typeof argument === 'number' || argStr === '[object Number]') {
		return new Date(argument);
	} else {
		return new Date(argument);
	}
};

export const getFormattedDate = _d => {
	const d = getDate(_d);
	return `${('0' + d.getDate()).slice(-2)}-${('0' + (d.getMonth() + 1)).slice(-2)}-${d.getFullYear()}`;
};

export const getFormattedDateYMD = _d => {
	const d = getDate(_d);
	return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}-${('0' + d.getDate()).slice(-2)}`;
};

export const getNewUntilDate = (d, days) => {
	const newDate = getDate(d);
	newDate.setDate(newDate.getDate() + days - 1);
	return newDate;
};

export const getFormattedNewUntilDate = (d, days) => {
	const newDate = getNewUntilDate(d, days);
	return getFormattedDate(newDate);
};

export const getLocaleDate = dString => {
	if (dString === null || dString === '') return null;
	if (typeof dString === 'string') {
		const d = dString.split('T')[0].split('-');
		return new Date(d[0], d[1] - 1, d[2], 0, 0, 0);
	} else {
		return new Date(dString.getFullYear(), dString.getMonth(), dString.getDate(), 0, 0, 0);
	}
};

export const isAllowedToEditActivation = __d => {
	if (__d === null) return true;
	const _d = getLocaleDate(__d);
	const d = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate(), 0, 0, 0);
	const _today = new Date();
	const today = new Date(_today.getFullYear(), _today.getMonth(), _today.getDate(), 0, 0, 0);

	return d > today;
};

export const isAllowedToModifyActivationDate = __d => {
	if (__d === null) return true;
	const _d = getLocaleDate(__d);
	const d = new Date(_d.getFullYear(), _d.getMonth(), _d.getDate(), 0, 0, 0);
	const _tomorrow = new Date();
	const tomorrow = new Date(_tomorrow.getFullYear(), _tomorrow.getMonth(), _tomorrow.getDate() + 1, 0, 0, 0);

	return d > tomorrow;
};
