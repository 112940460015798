import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { Button } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import LayoutHeader from 'components/LayoutHeader';
import ContentTable from 'components/ContentTable';
import FileDropper from './FileDropper';

import { useExcel } from 'hooks/use-excel';

const UPLOADICCIDS_MUTATION = gql`
	mutation UploadIccids($input: [ICCIDInput!]!) {
		UploadIccids(input: $input) {
			uploaded
			created
			updated
		}
	}
`;

const ChinaMobileUploader = ({onIccidsLoad}) => {
	const [updated, setUpdated] = useState(false);
	const [hasDuplicateIccid, setHasDuplicateIccid] = useState({status: false, duplicateIccids: []});
	const { data, setFiles } = useExcel(['iccid', 'pin2', 'puk1']);
	const [upload] = useMutation(UPLOADICCIDS_MUTATION, {
		variables: { input: data },
		onCompleted: () => {
			setFiles([]);
			setUpdated(true);
		},
	});

	useEffect(() => {
		if(Array.isArray(data)) {
			const iccids = data.map(e => e.iccid)
			const isDuplicate = iccids.some((item, idx) => iccids.indexOf(item) != idx);
			const duplicateIccids = iccids.filter((e, i, a) => a.indexOf(e) !== i) // [2, 4]
			setHasDuplicateIccid({duplicateIccids, status: isDuplicate})
			onIccidsLoad(iccids)
		}
	}, [data])
	

	return (
		<>
			<LayoutHeader
				actions={
					data.length > 1
						? [
								<Button key='0' onClick={() => setFiles([])} className='mr-2'>
									Borrar
								</Button>
								// <Button disabled={hasDuplicateIccid.status} key='1' onClick={() => upload()}>
								// 	Guardar
								// </Button>,
						  ]
						: null
				}
			>
			</LayoutHeader>
			{hasDuplicateIccid.status && <>
				<div className='alert alert-danger text-center'><p>Cuidado, los siguientes iccids están duplicados</p>
				<ul style={{display: 'flex', justifyContent: 'center'}}>
					{
						hasDuplicateIccid.duplicateIccids.map(iccid => <li key={uuidv4()}>{iccid}</li>)
					}
				</ul>
				</div>
			</>}
			{updated && <div className='alert alert-success text-center'>ICCIDs subidos</div>}
			{data.length > 1 ? (
				<ContentTable
					columns={data[0].downloadUrl ? ['iccid', 'pin2', 'puk1', 'Download URL']: ['iccid', 'pin2', 'puk1'] }
					content={data}
					render={(iccid) => (
						<tr key={uuidv4()}>
							<td>{iccid.iccid}</td>
							<td>{iccid.pin2}</td>
							<td>{iccid.puk1}</td>
						</tr>
					)}
				/>
			) : (
				<FileDropper setFiles={setFiles} />
			)}
		</>
	);
};

export default ChinaMobileUploader;
