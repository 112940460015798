import { useState, useEffect } from 'react';
import XLSX from 'xlsx';

export const useExcel = (validFields = []) => {
	const [files, setFiles] = useState([]);
	const [data, setData] = useState([]);

	useEffect(() => {
		if (files.length > 0) {
			for (const file of files) {
				readFileData(file, fileData => setData(() => parseXLS(fileData, validFields).slice(0, 1000)));
			}
		} else {
			setData([]);
		}
	}, [files]);

	return { files, setFiles, data };
};

export default useExcel;

const readFileData = (file, setFileData) => {
	let reader = new FileReader();

	reader.onload = () => {
		setFileData(reader.result);
	};

	// reader.onerror = reject;
	// reader.onabort = reject;

	reader.readAsBinaryString(file);
};

const parseXLS = (file, validFields) => {
	const wb = XLSX.read(file, { type: 'binary' });

	const wsname = wb.SheetNames[0];
	const ws = wb.Sheets[wsname];

	const data = parseSheet(XLSX.utils.sheet_to_json(ws, { header: 1 }), validFields);
	return data;
};

const parseSheet = (data, validFields) => {
	const titles = data.shift();

	const dataObject = [];

	for (const row of data) {
		const colData = {};
		for (let i = 0; i < row.length; i++) {
			const title = titles[i].toLowerCase().replace(/[\W\s]/g, '');
			if (validFields.includes(title)) {
				colData[title] = row[i]?.toString() || '';
			}
		}

		if (Object.keys(colData).length > 0) {
			dataObject.push(colData);
		}
	}

	return dataObject;
};
