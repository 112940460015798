import React from 'react';
import { Button } from 'reactstrap';

import ReactExport from 'react-export-excel';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ActivationListExportButton = ({ columns, filename, data, children }) => (
	<ExcelFile
		element={
			<Button color='primary' className='d-block mt-2 mx-auto'>
				{children}
			</Button>
		}
		filename={filename}
	>
		<ExcelSheet
			data={data.map(row => ({
				order_name: row.order_name || '',
				iccid: row.iccid || '',
				phone: row.phone || '',
				carrier: row.carrier || '',
				active_at: row.active_at || '',
				active_until: row.active_until || '',
			}))}
			name={filename}
		>
			{columns.map(col => (
				<ExcelColumn label={col.label} value={col.value} />
			))}
		</ExcelSheet>
	</ExcelFile>
);

export default ActivationListExportButton;
