export const getProcessedProviders = (providers) => {
	const filteredProviders = providers.filter(item => item.carrier);
	return filteredProviders.reduce((acc, curr) => {
		return {
			...acc,
			[curr.provider]: Array.isArray(acc[curr.provider]) 
				? [...acc[curr.provider], curr.carrier]
				: [curr.carrier]
		};
	},{});
};

export const generateSelectDataStructure = (elements, schema) => {
    const selectOptions = elements?.map(element => {
        const currentElement = schema.find(item => item.value === element);
        return {
            name: currentElement ? currentElement.name : element,
            value: element
        };
    });

    const mainOption = schema.find(item => item.value === 'title');
    return [
        {
            name: mainOption.name,
            value: ''
        },
        ...selectOptions
    ];
};

export const isExportForProviders = (search) => {
    return (search.carrier === 'TMOBILE-NORTH-AMERICA' || search.carrier === 'TMOBILE-US-ONLY')
        || search.provider === 'panysim';
};