import React from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

const LinksBetween = ({ maxPages, currentPage, onGoToPage, span = 4 }) => {
	const from = Math.max(2, Math.min(currentPage - span, maxPages - 2 * span));
	const to = Math.min(maxPages, Math.max(currentPage + span, from - 2 + 2 * span));

	let links = [
		<PaginationItem className={`page-item ${currentPage === 1 && 'active'}`} key={`link-1-${maxPages}`}>
			<PaginationLink className='page-link' href='#' onClick={() => onGoToPage(1)}>
				1
			</PaginationLink>
		</PaginationItem>,
	];

	for (let i = from; i <= to; i++) {
		let current = i === currentPage ? 'active' : '';

		links.push(
			<PaginationItem className={`page-item ${current}`} key={`link-${i}-${maxPages}`}>
				<PaginationLink className='page-link' href='#' onClick={() => onGoToPage(i)}>
					{i}
				</PaginationLink>
			</PaginationItem>
		);
	}

	if (to < maxPages) {
		links.push(
			<PaginationItem className={`page-item ${maxPages}`} key={`link-${maxPages}-${maxPages}`}>
				<PaginationLink className='page-link' href='#' onClick={() => onGoToPage(maxPages)}>
					{maxPages}
				</PaginationLink>
			</PaginationItem>
		);
	}

	return links;
};

const Paginator = ({ maxPages, currentPage, onGoToPage }) =>
	!maxPages || maxPages <= 1 ? null : (
		<Pagination className='mt-3 d-flex justify-content-center'>
			<PaginationItem className={`page-item ${currentPage === 1 && 'disabled'}`}>
				<PaginationLink className='page-link' href='#' onClick={() => onGoToPage(currentPage - 1)}>
					<span aria-hidden='true'>&laquo;</span>
					<span className='sr-only'>Anterior</span>
				</PaginationLink>
			</PaginationItem>
			<LinksBetween maxPages={maxPages} currentPage={currentPage} onGoToPage={onGoToPage} />
			<PaginationItem className={`page-item ${currentPage === maxPages && 'disabled'}`}>
				<PaginationLink className='page-link' href='#' onClick={() => onGoToPage(currentPage + 1)}>
					<span aria-hidden='true'>&raquo;</span>
					<span className='sr-only'>Siguiente</span>
				</PaginationLink>
			</PaginationItem>
		</Pagination>
	);

export default Paginator;
