import React from 'react';
import { BulkActivation } from '../../components/GeneralProviders/BulkActivation';
import { ConsultIccid } from '../../components/GeneralProviders/ConsultIccid';
import { DisableIccid } from '../../components/GeneralProviders/DisableIccid';
import { ActivationTopup } from '../../components/GeneralProviders/ActivationTopup';
import useBundlesProvider from 'hooks/useBundlesProvider';
import { apiProviders, apiProvisionings } from 'constants/globals';

const BASE_URL = `${apiProvisionings}/ofr`;

const Orange = () => {
	const { dataBundles } = useBundlesProvider(`${apiProviders}/ofr`);

	return (
		<>
			<ConsultIccid BaseURL={BASE_URL} />
			{/* <ActivationTopup BaseURL={apiProvisionings} providerCode={PROVIDER_CODE} bundles={dataBundles} /> */}

			<BulkActivation
				dataKey='msisdns'
				name='msisdn'
				bundles={dataBundles}
				BaseURL={apiProvisionings}
				providerCode={'ofr'}
			/>

			<DisableIccid BaseURL={BASE_URL} />
		</>
	);
};

export default Orange;
