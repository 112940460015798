export const CARRIERS_SELECT_SCHEMA = [
    { 
        name: 'Operador' ,
        value: 'title'
    },
    { 
        name: 'Prepaid-T3U' ,
        value: 'Prepaid-T3U'
    },
    { 
        name: 'Marruecos' ,
        value: 'morocco'
    },
    { 
        name: 'TM-IDD' ,
        value: 'TM-IDD'
    },
    { 
        name: 'TMOBILE-US-ONLY' ,
        value: 'TMOBILE-US-ONLY'
    },
    { 
        name: 'TMOBILE-NORTH-AMERICA' ,
        value: 'TMOBILE-NORTH-AMERICA'
    },
    { 
        name: '3UK',
        value: '3uk'
    }
];

export const PROVIDERS_SELECT_SCHEMA = [
    { 
        name: 'Proveedor' ,
        value: 'title'
    },
    { 
        name: 'Travelrelay' ,
        value: 'travelrelay'
    },
    { 
        name: 'Marruecos' ,
        value: 'morocco'
    },
    { 
        name: '3UK' ,
        value: '3uk'
    },
    { 
        name: 'Panysim' ,
        value: 'panysim'
    }
];