import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Row } from 'reactstrap';

import { useAlert, usePacksProvider } from 'hooks';
import { postActivationTopup } from 'services/apiProviders';

/**
 * @typedef {Object} ActivationTopupProps
 * @property {string} baseURL - La URL base.
 * @property {string} providerCode - El código del proveedor.
 * @property {Array} bundles - Un array de objetos que representa los bundles.
 */

export const ActivationTopup = ({ BaseURL, providerCode, bundles }) => {
	// const { packs, packSelected, activationFormValues, setPackSelected, setActivationFormValues } = usePacksProvider();
	const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);

	const [selectedBundle, setSelectedBundle] = useState({
		idBundle: 0,
		nameBundle: '',
	});

	const { idBundle } = selectedBundle;

	const [activationFormValues, setActivationFormValues] = useState({
		iccid: '',
		pack: 0,
	});

	const [alert, showAlert, hideAlert] = useAlert({
		status: false,
		message: '',
		type: '',
	});

	const onActivationChange = e => {
		if (e.target.name === 'pack') {
			const valueBundle = Number(e.target.value);
			const { description } = bundles.filter(pack => pack.id === valueBundle)[0];

			setSelectedBundle({ ...selectedBundle, idBundle: valueBundle, nameBundle: description });
			// setPackSelected(e.target.value);
		}
		setActivationFormValues({ ...activationFormValues, [e.target.name]: e.target.value });
	};

	const onSubmitActiveTopup = async e => {
		e.preventDefault();
		setSaveBtnIsDisabled(true);
		const { iccid, pack } = activationFormValues;

		const { message, type } = await postActivationTopup(BaseURL, providerCode, iccid, pack);

		showAlert(message, type);

		setSaveBtnIsDisabled(false);

		setTimeout(() => {
			hideAlert();
		}, 7000);
	};

	return (
		<Card>
			<CardBody>
				{alert.status && (
					<Alert color={alert.type} className='text-center animated fadeDown'>
						{JSON.stringify(alert.message)}
					</Alert>
				)}
				<CardTitle tag='h5'> Top up</CardTitle>
				<Form onSubmit={onSubmitActiveTopup}>
					<Row>
						<Col xs={12} md={4}>
							<FormGroup>
								<Input onChange={onActivationChange} name='iccid' placeholder='# Iccid' />
							</FormGroup>
						</Col>
						<Col xs={12} md={4}>
							<FormGroup>
								<Input type='select' value={idBundle} name='pack' onChange={onActivationChange}>
									{bundles?.map((item, idx) => (
										<option defaultValue={'Selecciona un bundle'} value={item.id} key={idx}>
											{item.description}
										</option>
									))}
								</Input>
							</FormGroup>
						</Col>
						<Col xs={12} md={2}>
							<FormGroup>
								<Button
									color='info'
									disabled={
										saveBtnIsDisabled || !activationFormValues.iccid || !activationFormValues.pack
									}
									title='Submit'
								>
									Activar top up
								</Button>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CardBody>
		</Card>
	);
};

ActivationTopup.propTypes = {
	BaseURL: PropTypes.string.isRequired,
	providerCode: PropTypes.string.isRequired,
	bundles: PropTypes.arrayOf(PropTypes.object).isRequired,
};
