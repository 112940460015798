import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Row } from 'reactstrap';

import { useAlert } from 'hooks/useAlert';
import { formatMBytes } from 'helpers/format';

import { getConsultIccid } from 'services/apiProviders';

/**
 * @typedef {Object} ConsultIccidProps
 * @property {string} BaseURL - La URL base.
 */

export const ConsultIccid = ({ BaseURL }) => {
	const [iccid, setIccid] = useState('');
	const [dataInfoIccid, setDataInfoIccid] = useState({});
	const [isDisabledButton, setIsDisabledButton] = useState(false);

	const [alert, showAlert, hideAlert] = useAlert({
		status: false,
		message: '',
		type: '',
	});

	const onChange = e => setIccid(e.target.value);

	const onConsultIccid = async e => {
		setIsDisabledButton(true);
		e.preventDefault();

		const { data, message, type } = await getConsultIccid(BaseURL, iccid);

		setDataInfoIccid(data);
		showAlert(message, type);

		setIsDisabledButton(false);

		setTimeout(() => {
			hideAlert();
		}, 7000);
	};

	return (
		<Card>
			<CardBody>
				{alert.status && (
					<Alert color={alert.type} className='text-center animated fadeDown'>
						{JSON.stringify(alert.message)}
					</Alert>
				)}

				<CardTitle tag='h5'>Consultar</CardTitle>
				<Form onSubmit={onConsultIccid}>
					<Row>
						<Col xs={12} md={4}>
							<FormGroup>
								<Input onChange={onChange} name='iccid' placeholder='# Iccid' />
							</FormGroup>
						</Col>
						<Col xs={12} md={2}>
							<FormGroup>
								<Button color='info' disabled={!iccid || isDisabledButton} title='Submit'>
									Consultar
								</Button>
							</FormGroup>
						</Col>
					</Row>
				</Form>
				{Object.keys(dataInfoIccid).length !== 0 && (
					<div>
						<h6 className='bg-red-300'>Bundle: {dataInfoIccid.bundles[0].name}</h6>
						{/* <h6>Descripción: {dataInfoIccid.provisioningDetails?.description}</h6> */}
						<h6>Estado: {dataInfoIccid.status}</h6>
						<ul>
							<li>Total de datos: {formatMBytes(dataInfoIccid.bundles[0].data.total)}</li>
							<li>Total de días: {dataInfoIccid.bundles[0].totalDays}</li>

							{dataInfoIccid.status !== 'PROVISIONING_SUSPENDED' && (
								<>
									<li>{formatMBytes(dataInfoIccid?.bundles[0]?.data.remaining)} restantes</li>
									<li>{dataInfoIccid?.bundles[0].data.usedPercent.toFixed(2)}% de datos usados</li>

									<li>Dias restantes: {dataInfoIccid?.bundles[0].remainingDays}</li>

									<li>Fecha de activación: {dataInfoIccid?.bundles[0].activationDate}</li>
									<li>Fecha de expiración: {dataInfoIccid?.bundles[0].expirationDate}</li>
								</>
							)}
						</ul>
					</div>
				)}
			</CardBody>
		</Card>
	);
};

ConsultIccid.propTypes = {
	BaseURL: PropTypes.string.isRequired,
};
