import { useState, useEffect } from 'react';
import { getBundlesProviders } from 'services/apiProviders';

const useBundlesProvider = BaseURL => {
	const [dataBundles, setDataBundles] = useState([{ id: 0, description: 'Selecciona un bundle' }]);

	const callApiBundles = async () => {
		try {
			const resp = await getBundlesProviders(BaseURL);

			const { data } = resp.data;

			setDataBundles(dataBundles => [...dataBundles, ...data]);
		} catch (error) {
			console.error('Error fetching bundles:', error);
		}
	};

	useEffect(() => {
		callApiBundles();
	}, []);

	return { dataBundles };
};

export default useBundlesProvider;
