import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getFormattedDateYMD, getLocaleDate } from '../../helpers/activationDateHelpers';
import FormEditActivations from './FormEditActivations';

const UPDATEORDERITEM_MUTATION = gql`
	mutation OrderItemUpdate($id: ID!, $input: OrderUpdateInput!) {
		OrderItemUpdate(id: $id, input: $input) {
			id
			title
			iccid
			active_at
			active_until
			activation_requested
			order_number
			comments
			revision
			days
		}
	}
`;

const CANCELACTIVATION_MUTATION = gql`
	mutation CancelActivation($id: ID!) {
		OrderItemCancelActivation(id: $id) {
			id
			title
			order_id
			order_number
			iccid
			activable
			active_at
			active_until
			activation_requested
			sku
			product_id
			carrier
			days
			sent
			comments
			deleted_at
		}
	}
`;

const ActivationsEditModal = ({ item, buttonLabel, className }) => {
	const [isModalOpen, setModalOpen] = useState(false);
	const [isSaving, setSaving] = useState(false);
	const [data, setData] = useState({});

	useEffect(() => {
		setData({
			id: item.id,
			activable: item.activable,
			days: item.days,
			iccid: item.iccid,
			active_at: item.active_at && item.active_at !== '' ? getLocaleDate(item.active_at) : '',
			active_until: item.active_until && item.active_until !== '' ? getLocaleDate(item.active_until) : '',
			activation_priority: item.activation_priority,
			activation_requested: item.activation_requested,
			carrier: item.carrier,
			revision: item.revision,
			comments: item.comments,
		});
	}, [isModalOpen, item]);

	const [update] = useMutation(UPDATEORDERITEM_MUTATION, {
		variables: {
			id: item.id,
			input: item.deleted_at
				? { comments: data.comments }
				: {
						iccid: data.iccid,
						active_at: data.active_at && data.active_at !== '' ? getFormattedDateYMD(data.active_at) : null,
						active_until:
							data.active_until && data.active_until !== ''
								? getFormattedDateYMD(data.active_until)
								: null,
						activation_priority: data.activation_priority,
						activation_requested: data.activation_requested,
						carrier: data.carrier,
						revision: data.revision,
						comments: data.comments,
				  },
		},
		onCompleted() {
			setSaving(false);
		},
	});
	const [cancel] = useMutation(CANCELACTIVATION_MUTATION, { variables: { id: item.id } });

	return (
		<div>
			<Button color={isModalOpen ? 'info' : 'primary'} onClick={() => setModalOpen(!isModalOpen)}>
				{buttonLabel}
			</Button>
			<Modal isOpen={isModalOpen} toggle={() => setModalOpen(!isModalOpen)} className={className}>
				<ModalHeader toggle={() => setModalOpen(!isModalOpen)}>
					#{item.order_number} - {item.title}
				</ModalHeader>
				<ModalBody>
					<FormEditActivations
						item={item}
						data={data}
						onChange={newData => setData({ ...data, ...newData })}
					/>
				</ModalBody>
				<ModalFooter>
					{!item.deleted_at && (
						<Button
							color='primary'
							className='mr-auto'
							disabled={isSaving}
							onClick={async () => {
								await cancel();
								setModalOpen(false);
							}}
						>
							Cancelar Tarjeta
						</Button>
					)}
					<Button
						color={isSaving ? 'success' : 'primary'}
						disabled={isSaving}
						onClick={async () => {
							setSaving(true);
							update();
						}}
					>
						{isSaving ? (
							<>
								<i className='fa fa-cog fa-spin fa-fw' />
								Guardando…
							</>
						) : (
							'Confirmar Cambios'
						)}
					</Button>
					<Button color='secondary' onClick={() => setModalOpen(!isModalOpen)} disabled={isSaving}>
						Cerrar
					</Button>
				</ModalFooter>
			</Modal>
		</div>
	);
};

export default ActivationsEditModal;
