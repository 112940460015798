import { useState } from 'react';
import PropTypes from 'prop-types';

export const useAlert = initialState => {
	const [alert, setAlert] = useState(initialState);

	const showAlert = (message, type) => {
		setAlert({
			status: true,
			message,
			type,
		});
	};

	const hideAlert = () => {
		setAlert({
			status: false,
			message: '',
			type: '',
		});
	};

	return [alert, showAlert, hideAlert];
};

// Prop types para el estado de la alerta
const alertPropTypes = PropTypes.shape({
	status: PropTypes.bool.isRequired,
	message: PropTypes.string.isRequired,
	type: PropTypes.string.isRequired,
});

// Prop types para las funciones showAlert y hideAlert
const alertFunctionsPropTypes = PropTypes.shape({
	showAlert: PropTypes.func.isRequired,
	hideAlert: PropTypes.func.isRequired,
});

// Prop types para el hook useAlert
useAlert.propTypes = {
	initialState: alertPropTypes.isRequired,
};

// Prop types para el array que devuelve useAlert
useAlert.returnPropTypes = PropTypes.arrayOf(PropTypes.oneOfType([alertPropTypes, alertFunctionsPropTypes]));
