import React from 'react';
import Paginator from './Paginator';

class PaginableView extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			currentPage: this.props.currentPage || 1,
		};
	}

	onGoToPage = page => {
		if (page !== this.state.currentPage) {
			this.props.onGoToPage && this.props.onGoToPage(page);
			this.setState({
				currentPage: page,
			});
		}
	};

	render() {
		return (
			<>
				{this.props.children}
				<Paginator
					onGoToPage={this.onGoToPage}
					maxPages={this.props.maxItems ? Math.ceil(this.props.maxItems / 10) : 0}
					currentPage={this.state.currentPage}
				/>
			</>
		);
	}
}

export default PaginableView;
