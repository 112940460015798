import Loadable from 'react-loadable';
import Loading from './components/Loading';

import DefaultLayout from './containers/DefaultLayout/DefaultLayout';

const Dashboard = Loadable({
	loader: () => import('./views/Dashboard/Dashboard'),
	loading: Loading,
});

const Ventas = Loadable({
	loader: () => import('./views/Ventas/Ventas'),
	loading: Loading,
});

const VentasSingle = Loadable({
	loader: () => import('./views/Ventas/VentasSingle'),
	loading: Loading,
});

// const Activaciones = Loadable({
// 	loader: () => import('./views/Activaciones/Activaciones'),
// 	loading: Loading,
// });
import Lanka from './views/Lanka/Lanka';
import Cloud9 from './views/Cloud9/Cloud9';
import ChinaMobile from './views/ChinaMobile/ChinaMobile';
import ThreeAustria from './views/ThreeAustria/ThreeAustria';
import Orange from 'views/Orange/Orange';
import SparkleView from 'views/Sparkle/SparkleView';

import Activaciones from './views/Activaciones/Activaciones';

const ActivacionesStatistics = Loadable({
	loader: () => import('./views/Activaciones/ActivacionesStatistics'),
	loading: Loading,
});

const Productos = Loadable({
	loader: () => import('./views/Productos/Productos'),
	loading: Loading,
});

const ProductDetails = Loadable({
	loader: () => import('./views/Productos/ProductDetails'),
	loading: Loading,
});

const Logs = Loadable({
	loader: () => import('./views/Logs/Logs'),
	loading: Loading,
});

const ICCIDs = Loadable({
	loader: () => import('./views/ICCIDs/ICCIDs'),
	loading: Loading,
});

const ICCIDUploader = Loadable({
	loader: () => import('./views/ICCIDs/ICCIDUploader'),
	loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
	{ path: '/', exact: true, name: 'Inicio', component: DefaultLayout },

	// Propios
	{ path: '/ventas', exact: true, name: 'Ventas', component: Ventas },
	{ path: '/ventas/page/:page', exact: true, name: 'Venta', component: Ventas },
	{ path: '/ventas/:orderId', name: 'Detalle de Venta', component: VentasSingle },

	{ path: '/activaciones/statistics', exact: true, name: 'Estadísticas', component: ActivacionesStatistics },
	{ path: '/activaciones/:status?', exact: true, name: 'Activaciones', component: Activaciones },

	{ path: '/productos', exact: true, name: 'Productos', component: Productos },
	{ path: '/productos/:productId', exact: true, name: 'Detalle de Producto', component: ProductDetails },

	{ path: '/iccids', exact: true, name: 'ICCIDs', component: ICCIDs },
	{ path: '/iccids/upload', exact: true, name: 'ICCIDs', component: ICCIDUploader },

	{ path: '/logs/:search?', name: 'Logs', component: Logs },
	// Proveedores
	{ path: '/proveedores/lanka', exact: true, name: 'Lanka', component: Lanka },
	{ path: '/proveedores/cloud9', exact: true, name: 'Cloud9', component: Cloud9 },
	{ path: '/proveedores/china-mobile', exact: true, name: 'ChinaMobile', component: ChinaMobile },
	{ path: '/proveedores/three-austria', exact: true, name: 'ThreeAustria', component: ThreeAustria },
	{ path: '/proveedores/orange', exact: true, name: 'Orange', component: Orange },
	// { path: '/proveedores/sparkle', exact: true, name: 'Sparkle', component: SparkleView },

	// CoreUI
	{ path: '/dashboard', name: 'Dashboard', component: Dashboard },
];

export default routes;
