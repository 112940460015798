import React from 'react';
import { useDropzone } from 'react-dropzone';

const FileDropper = ({ setFiles }) => {
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop: files => setFiles(files) });

	return (
		<div
			{...getRootProps()}
			className={`animated fadeOut alert alert-${isDragActive ? 'warning' : 'info'} text-center p-0`}
		>
			<input
				{...getInputProps()}
				accept='image/png, image/jpeg, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
			/>
			<h3
				className='mt-0 mb-0 p-4'
				style={{
					borderStyle: 'dashed',
					borderWidth: '5px',
					borderRadius: '5px',
					borderColor: 'rgba(0,0,0,0.25)',
					cursor: 'pointer',
				}}
			>{`${isDragActive ? 'Suelta' : 'Arrastra'} tu excel aquí`}</h3>
		</div>
	);
};

export default FileDropper;
