import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { useAlert } from 'hooks/useAlert';

import { deleteIccid } from 'services/apiProviders';

/**
 * @typedef {Object} DisableIccidProps
 * @property {string} BaseURL - La URL base.
 */

export const DisableIccid = ({ BaseURL }) => {
	const [iccid, setIccid] = useState('');

	const [isDisabledButton, setIsDisabledButton] = useState(false);

	const [alert, showAlert, hideAlert] = useAlert({
		status: false,
		message: '',
		type: '',
	});

	const onDeleteChange = e => {
		setIccid(e.target.value);
	};

	const onDeleteIccid = async e => {
		setIsDisabledButton(true);
		e.preventDefault();

		const { message, type } = await deleteIccid(BaseURL, iccid);

		showAlert(message, type);

		setIsDisabledButton(false);

		setTimeout(() => {
			hideAlert();
		}, 7000);
	};

	return (
		<Card>
			<CardBody>
				{alert.status && (
					<Alert color={alert.type} className='text-center animated fadeDown'>
						{JSON.stringify(alert.message)}
					</Alert>
				)}
				<CardTitle tag='h5'>Desactivar</CardTitle>
				<Form onSubmit={onDeleteIccid}>
					<Row>
						<Col xs={12} md={4}>
							<FormGroup>
								<Input onChange={onDeleteChange} name='iccid' placeholder='# Iccid' />
							</FormGroup>
						</Col>

						<Col xs={12} md={4}>
							<FormGroup>
								<Button color='danger' disabled={!iccid || isDisabledButton} type='Submit'>
									Desactivar
								</Button>
							</FormGroup>
						</Col>
					</Row>
				</Form>
			</CardBody>
		</Card>
	);
};

DisableIccid.propTypes = {
	BaseURL: PropTypes.string.isRequired,
};
