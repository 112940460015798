import React from 'react';
import { Col, Form, FormGroup, /* Button, */ Input, Row } from 'reactstrap';

const ActivationsSearchBar = ({ handleChange, search, onSubmit, providers, carriers }) => (
	<Form
		onSubmit={e => {
			e.preventDefault();
			onSubmit();
		}}
	>
		<Row>
			<Col xs={12} md={2}>
				<FormGroup>
					<Input
						onChange={handleChange}
						name='order_number'
						placeholder='# Orden'
						value={search.order_number}
					/>
				</FormGroup>
			</Col>
			<Col xs={12} md={2}>
				<FormGroup>
					<Input onChange={handleChange} name='iccid' placeholder='# ICCID' value={search.iccid} />
				</FormGroup>
			</Col>
			<Col xs={12} md={2}>
				<FormGroup>
					<Input onChange={handleChange} name='phone' placeholder='Teléfono' value={search.phone} />
				</FormGroup>
			</Col>
			<Col xs={12} md={2}>
				<FormGroup disabled={search.status !== 'search'}>
					<Input
						onChange={handleChange}
						name='email'
						placeholder='E-Mail'
						value={search.email}
						disabled={search.status !== 'search'}
					/>
				</FormGroup>
			</Col>
			<Col xs={12} md={2} className='text-center'>
				<Input
					type='select'
					name='provider'
					value={search.provider}
					className='d-inline-block w-100 mb-2'
					onChange={handleChange}
				>
					{providers && 
						providers.map((provider, index) => 
						<option key={index} value={provider.value}>{provider.name}</option>)
					}
				</Input>
			</Col>
			<Col xs={12} md={2} className='text-center'>
				<Input
					type='select'
					name='carrier'
					value={search.carrier}
					className='d-inline-block w-100 mb-2'
					onChange={handleChange}
				>
					{carriers && 
						carriers.map((carrier, index) => 
							<option key={index} value={carrier.value}>{carrier.name}</option>)
					}
				</Input>
			</Col>
			<Col xs={12} md={2}>
				<FormGroup>
					<Input
						type='date'
						onChange={handleChange}
						name='active_at'
						placeholder='Fecha de Activación'
						value={search.active_at}
					/>
				</FormGroup>
			</Col>
			{/*
				<Col xs={12} md={2} className='text-center'>
					<Button className='d-inline-block w-100 mb-2' onClick={onSubmit}>
						Buscar
					</Button>
				</Col>
			*/}
		</Row>
	</Form>
);

export default ActivationsSearchBar;
