export default {
	items: [
		// {
		// 	name: 'Inicio',
		// 	url: '/dashboard',
		// 	icon: 'icon-home'
		// },
		// {
		// 	divider: true
		// },
		{
			title: true,
			name: 'SIM Físicas',
			wrapper: {
				element: '',
				attributes: {},
			},
			class: '',
			visibleBy: ['logistics', 'user', 'manager', 'admin'],
		},
		{
			name: 'Activaciones',
			url: '/activaciones/search',
			icon: 'fa fa-search',
			visibleBy: ['logistics', 'user', 'manager', 'admin'],
		},
		{
			name: 'Estadísticas',
			url: '/activaciones/statistics',
			icon: 'fa fa-area-chart',
			visibleBy: ['admin'],
		},
		{
			name: 'ICCIDs',
			url: '/iccids',
			icon: 'fa fa-mixcloud',
			visibleBy: ['user', 'manager', 'admin'],
		},
		// {
		// 	title: true,
		// 	name: 'Productos',
		// 	wrapper: {
		// 		element: '',
		// 		attributes: {},
		// 	},
		// 	class: '',
		// 	visibleBy: ['user', 'manager', 'admin'],
		// },
		{
			name: 'Productos',
			url: '/productos',
			icon: 'icon-drawer',
			visibleBy: ['user', 'manager', 'admin'],
		},
		// {
		// 	title: true,
		// 	name: 'Eventos',
		// 	wrapper: {
		// 		element: '',
		// 		attributes: {}
		// 	},
		// 	class: '',
		// 	visibleBy: ['user', 'manager', 'admin']
		// },
		{
			name: 'Revisar Eventos',
			url: '/logs',
			icon: 'fa fa-pencil',
			visibleBy: ['user', 'manager', 'admin'],
		},
		{
			title: true,
			name: 'eSIMS',
			wrapper: {
				element: '',
				attributes: {},
			},
			class: '',
			visibleBy: ['user', 'manager', 'admin'],
		},
		{
			name: 'Buscar',
			url: 'https://esim-vault.holafly.com/esims/search',
			icon: 'fa fa-search',
			class: 'inactive',
			visibleBy: ['user', 'manager', 'admin'],
		},
		{
			name: 'Reenviar eSIM',
			url: 'https://esim-vault.holafly.com/esims/send',
			icon: 'fa fa-envelope-o',
			class: 'inactive',
			visibleBy: ['user', 'manager', 'admin'],
		},
		{
			title: true,
			name: 'PROVEEDORES',
			wrapper: {
				element: '',
				attributes: {},
			},
			class: '',
			visibleBy: ['logistics', 'user', 'manager', 'admin'],
		},
		{
			name: 'LTM',
			url: '/proveedores/lanka',
			icon: 'fa fa-cog',
			visibleBy: ['logistics', 'user', 'manager', 'admin'],
		},
		{
			name: 'NGD & GD',
			url: '/proveedores/cloud9',
			icon: 'fa fa-cog',
			visibleBy: ['logistics', 'user', 'manager', 'admin'],
		},
		{
			name: 'CHM',
			url: '/proveedores/china-mobile',
			icon: 'fa fa-cog',
			visibleBy: ['logistics', 'user', 'manager', 'admin'],
		},
		{
			name: '3AUS',
			url: '/proveedores/three-austria',
			icon: 'fa fa-cog',
			visibleBy: ['logistics', 'user', 'manager', 'admin'],
		},
		{
			name: 'OFR',
			url: '/proveedores/orange',
			icon: 'fa fa-cog',
			visibleBy: ['logistics', 'user', 'manager', 'admin'],
		},
		// {
		// 	name: 'SPK',
		// 	url: '/proveedores/sparkle',
		// 	icon: 'fa fa-cog',
		// 	visibleBy: ['logistics', 'user', 'manager', 'admin'],
		// },
	],
};
