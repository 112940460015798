import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Button, Card, CardBody, CardTitle, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import { UploadExcel } from './UploadExcel';
import { useAlert } from 'hooks/useAlert';
import { postBulk } from 'services/apiProviders';

/**
 * @typedef {Object} BulkActivationProps
 * @property {string} dataKey - La clave de los datos específicos, como nombres.
 * @property {string} name - El nombre.
 * @property {Array} bundles - Un array de objetos que representa los bundles.
 * @property {string} BaseURL - La URL base.
 * @property {string} providerCode - El código del proveedor.
 */

export const BulkActivation = ({ dataKey, name, bundles, BaseURL, providerCode }) => {
	const [isDisabledButton, setIsDisabledButton] = useState(false);

	const [selectedBundle, setSelectedBundle] = useState({
		idBundle: 0,
		nameBundle: '',
	});

	const { idBundle } = selectedBundle;

	const [activationBulkFormValues, setActivationBulkFormValues] = useState({
		iccids: [],
		[dataKey]: [],
		idBundle: 0,
		bagName: '',
	});

	const [duplicatedValues, setDuplicatedValues] = useState({
		isDuplicateIccids: false,
		isDuplicateData: false,
	});

	const { isDuplicateIccids, isDuplicateData } = duplicatedValues;

	const [alert, showAlert, hideAlert] = useAlert({
		status: false,
		message: '',
		type: '',
	});

	const onActivationBulkChange = e => {
		if (e.target.name === 'idBundle') {
			const valueBundle = Number(e.target.value);

			const { description } = bundles.filter(pack => pack.id === valueBundle)[0];

			setSelectedBundle({ ...selectedBundle, idBundle: valueBundle, nameBundle: description });
		}

		setActivationBulkFormValues({ ...activationBulkFormValues, [e.target.name]: e.target.value });
	};

	const onIccidsLoad = data => {
		const iccids = data.iccids;
		const isArrayIccidsAndData = Array.isArray(iccids) && Array.isArray(data[dataKey]);

		if (isArrayIccidsAndData) {
			const isDuplicateIccids = iccids.some((item, idx) => iccids.indexOf(item) != idx);
			const isDuplicateData = data[dataKey].some((item, idx) => data[dataKey].indexOf(item) != idx);

			setDuplicatedValues({ ...duplicatedValues, isDuplicateIccids, isDuplicateData });

			setActivationBulkFormValues({ ...activationBulkFormValues, iccids, [dataKey]: data[dataKey] });
		}
	};

	const onBulkClick = async () => {
		setIsDisabledButton(true);
		const { bagName, iccids, idBundle } = activationBulkFormValues;
		const { message, type } = await postBulk(BaseURL, providerCode, {
			bagName,
			iccids,
			[dataKey]: activationBulkFormValues[dataKey],
			bundleId: Number(idBundle),
		});
		showAlert(message, type);

		setIsDisabledButton(false);

		setTimeout(() => {
			hideAlert();
		}, 7000);
	};

	return (
		<>
			<Card>
				<CardBody>
					{alert.status && (
						<Alert color={alert.type} className='text-center animated fadeDown'>
							{JSON.stringify(alert.message)}
						</Alert>
					)}
					<CardTitle tag='h5'>Activación Bulk</CardTitle>
					<Form>
						<Row>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										onChange={onActivationBulkChange}
										name='bagName'
										placeholder='# Nombre de la bolsa'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={4}>
								<FormGroup>
									<Input
										type='select'
										value={idBundle}
										name='idBundle'
										onChange={onActivationBulkChange}
									>
										{bundles?.map((item, idx) => (
											<option defaultValue={'Selecciona un bundle'} value={item.id} key={idx}>
												{item.description}
											</option>
										))}{' '}
										*
									</Input>
								</FormGroup>
							</Col>

							<Col xs={12} md={1}>
								<FormGroup>
									<Button
										color='info'
										disabled={
											!activationBulkFormValues.iccids.length ||
											!activationBulkFormValues[dataKey].length ||
											isDuplicateIccids ||
											isDuplicateData ||
											activationBulkFormValues.idBundle === 0 ||
											!activationBulkFormValues.bagName ||
											isDisabledButton
										}
										title='Submit'
										onClick={onBulkClick}
									>
										Bulk
									</Button>
								</FormGroup>
							</Col>
						</Row>

						<Alert color='warning' className='text-center animated fadeDown'>
							<p>El excel debe "solo" contener las siguientes columnas en el siguiente orden</p>
							<p>| iccid | {name} |</p>
						</Alert>
						<UploadExcel name={dataKey} dataKey={name} onIccidsLoad={onIccidsLoad} />
					</Form>
				</CardBody>
			</Card>
		</>
	);
};

BulkActivation.propTypes = {
	dataKey: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	BaseURL: PropTypes.string.isRequired,
	providerCode: PropTypes.string.isRequired,
	bundles: PropTypes.arrayOf(PropTypes.object).isRequired,
};
