import React from 'react';
import { NavLink } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';

const ActivationStatusSelector = () => {
	return (
		<Nav className='pt-3 pb-5'>
			{ActivationLinks.map(link => (
				<NavItem>
					<NavLink
						className='border rounded mr-2 border-primary p-2'
						to={link.url}
						activeClassName='text-white bg-primary'
					>
						<i className={`${link.icon} mr-2`}></i>
						<span>{link.name}</span>
					</NavLink>
				</NavItem>
			))}
		</Nav>
	);
};

const ActivationLinks = [
	{
		name: 'Buscar',
		url: '/activaciones/search',
		icon: 'fa fa-search',
		visibleBy: ['logistics', 'user', 'manager', 'admin'],
	},
	{
		name: 'Fecha Cercana',
		url: '/activaciones/next',
		icon: 'icon-calendar',
		visibleBy: ['user', 'manager', 'admin'],
	},
	{
		name: 'Sin ICCID ni fecha',
		url: '/activaciones/pendingAll',
		icon: 'icon-drawer',
		visibleBy: ['user', 'manager', 'admin'],
	},
	{
		name: 'Sin ICCID',
		url: '/activaciones/pendingICCID',
		icon: 'icon-screen-smartphone',
		visibleBy: ['user', 'manager', 'admin'],
	},
	{
		name: 'Sin Fecha',
		url: '/activaciones/pendingDate',
		icon: 'icon-calendar',
		visibleBy: ['user', 'manager', 'admin'],
	},
	{
		name: 'Pend. Activación',
		url: '/activaciones/pendingActivation',
		icon: 'fa fa-bookmark-o',
		visibleBy: ['user', 'manager', 'admin'],
	},
	{
		name: 'Activados',
		url: '/activaciones/activated',
		icon: 'fa fa-bookmark',
		visibleBy: ['user', 'manager', 'admin'],
	},
];

export default ActivationStatusSelector;
