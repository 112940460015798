import React, { useState, useEffect } from 'react';
import gql from 'graphql-tag';
import format from 'date-fns/format';
import { Button } from 'reactstrap';

import { useQuery } from '@apollo/react-hooks';
import LayoutHeader from '../../components/LayoutHeader';
import QueriableView from '../../components/QueriableView';
import PaginableView from '../../components/PaginableView';
import ActivationsSearchBar from './ActivationsSearchBar';
import ActivationStatusSelector from '../../components/ActivationStatusSelector';
import ActivationsTable from './ActivationsTable';
import ActivationListExportButton from './ActivationListExportButton';
import { exportProductsToExel } from '../../services/productExcel';
import { CARRIERS_SELECT_SCHEMA, PROVIDERS_SELECT_SCHEMA } from '../../constants';
import { generateSelectDataStructure, isExportForProviders } from '../../services/activaciones';

const SearchOrderItemsQuery = gql`
	query SearchOrderItemsBy($search: OrderItemSearchInput!, $page: Int) {
		SearchOrderItemsBy(search: $search, page: $page) {
			id
			title
			shop_id
			order_id
			order_number
			order_name
			iccid
			activable
			active_at
			active_until
			activation_requested
			activation_priority
			activator_provider_comments
			sku
			product_id
			carrier
			phone
			days
			sent
			comments
			revision
			deleted_at
		}
		SearchOrderItemsResultsCount(search: $search)
	}
`;

const GetProviders = gql`
	query {
		Providers{
			provider,
			carrier
		}
	}
`;

const Activations = ({ match: { params } }) => {

	const initialProvidersDataState = {
		data: [],
		providerOptions: [],
		carrierOptions: []
	};

	const [didSearch, setDidSearch] = useState(params.status !== 'search');
	const [page, setPage] = useState(1);
	const [providersData, setProvidersData] = useState(initialProvidersDataState);
	const [search, setSearch] = useState({
		status: params.status,
	});

	const { data } = useQuery(GetProviders);
	
	useEffect(() => {
		if(data){
			const providerList = data?.Providers.map(item => item.provider);
			const carrierList = data?.Providers
				.map(item => item.carrier)
				.flat()
				.filter(carrier => carrier !== '');

			const providersProcessedOptions = generateSelectDataStructure(providerList, PROVIDERS_SELECT_SCHEMA);
			const carriersProcessedOptions = generateSelectDataStructure(carrierList, CARRIERS_SELECT_SCHEMA);

			setProvidersData({
				providerOptions: providersProcessedOptions,
				carrierOptions: carriersProcessedOptions,
				data: data.Providers
			});
		}
	}, [data]);

	useEffect(() => {
		setPage(1);
		setSearch(s => ({ ...s, status: params.status }));
		setDidSearch(params.status !== 'search');
	}, [params.status]);

	useEffect(() => {
		const providerSelected = search.provider;
		
		// eslint-disable-next-line
		const {carrier, provider, ...rest} = search;
		const carrierList = providersData.data?.map(item => item.carrier).flat();
		const carrierOpt = generateSelectDataStructure(carrierList, CARRIERS_SELECT_SCHEMA);
		const productVariantSelected = providersData.data.find(item => search.provider === item.provider);

		const newCarrierOptions = providerSelected
			? carrierOpt.filter(item => 
				(productVariantSelected.carrier.includes(item.value) || item.value === '')
				&& item.name !== '')
			: carrierOpt.filter(item => item.name !== '');

		setProvidersData({
			...providersData,
			carrierOptions: newCarrierOptions
		});

		const isCarrierIncludeInProviders = newCarrierOptions.some(item => item.value === carrier);

		setSearch({
			...rest,
			...(search.provider ? {provider} : {carrier: ''}),
			...(isCarrierIncludeInProviders ? {} : {carrier: ''})
		});

		setDidSearch(true);
	}, [search.provider]);

	useEffect(() => {
		setDidSearch(true);
	}, [search.carrier]);

	
	const saveExcel = async () => {
		await exportProductsToExel(search);
	};
	
	return (
		<>
			<LayoutHeader>Buscador de Activaciones</LayoutHeader>
			<ActivationsSearchBar
				providers={providersData.providerOptions}
				carriers={providersData.carrierOptions}
				search={search}
				handleChange={({ target: { name, value } }) => {
					setSearch({
						...search,
						[name]: value.replace('#', '').replace(' ', ''),
					});
					if(name !== 'provider' && name !== 'carrier'){
						setDidSearch(true);
					}
				}}
				onSubmit={() => setSearch(search)}
			/>
			<ActivationStatusSelector />
			{didSearch && (
				<QueriableView
					query={SearchOrderItemsQuery}
					variables={{ search, page }}
					whileLoading='Buscando Activaciones'
					cache={false}
					fetchPolicy={'network-only'}
				>
					{({ data: { SearchOrderItemsBy, SearchOrderItemsResultsCount } }) => (
						<>
							<PaginableView
								currentPage={page}
								maxItems={SearchOrderItemsResultsCount}
								onGoToPage={setPage}
							>
								{SearchOrderItemsBy.length > 0 ? (
									<ActivationsTable orderItems={SearchOrderItemsBy} status={params.status} />
								) : (
									<div className='alert alert-info text-center'>No hay Resultados</div>
								)}
							</PaginableView>
							{SearchOrderItemsBy.length > 0 && (
								<ActivationListExportButton
									status={search.status}
									columns={exportButtonColumnsNames}
									filename={`${format(new Date(), 'yyyyMMdd')}_${status}_activaciones_holafly`}
									data={SearchOrderItemsBy}
								>
									Exportar datos
								</ActivationListExportButton>
							)} 
							{(SearchOrderItemsBy.length > 0 
								&& isExportForProviders(search)) && (
								<Button onClick={saveExcel} color='primary' className='d-block mt-2 mx-auto'>
									Exportar datos para proovedor
								</Button>
							)}
						</>
					)}
				</QueriableView>
			)}
		</>
	);
};

const exportButtonColumnsNames = [
	{ label: 'Order', value: 'order_name' },
	{ label: 'ICCID', value: 'iccid' },
	{ label: 'Phone', value: 'phone' },
	{ label: 'carrier', value: 'carrier' },
	{ label: 'Starting Date', value: 'active_at' },
	{ label: 'Ending Date', value: 'active_until' },
];

export default Activations;
