export const formatMBytes = (bytes, decimals = 2) => {
	if (bytes === 0 || bytes === '0') return '0 Mb';

	const k = 1024;
	const dm = decimals < 0 ? 0 : decimals;
	const sizes = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const i = Math.floor(Math.log(bytes) / Math.log(k));

	const result = parseFloat((bytes / Math.pow(k, i < 0 ? 0 : i)).toFixed(dm));
	const measurementUnits = sizes[i < 0 ? 0 : i];

	const value =
		measurementUnits === 'MB' ? (result / 1000).toFixed(dm) + ' ' + sizes[1] : result + ' ' + measurementUnits;

	return value;
};
