import React from 'react';

const Loading = ({ text = 'Cargando Módulo' }) => (
	<div className='d-flex justify-content-center p-5'>
		<div className='align-self-center p-5 m-auto text-center'>
			<i className='fa fa-cog fa-spin fa-2x fa-fw' />
			<p>{text}</p>
		</div>
	</div>
);

export default Loading;
