import React, { useEffect, useState } from 'react';
import LayoutHeader from '../../components/LayoutHeader';
import { Alert, Card, CardBody, CardTitle, Col, Form, FormGroup, Button, Input, Row } from 'reactstrap';


const packEndpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/ventamobile/packs`;

const initialActivationFormValues = {
	gb: '',
	iccid: '',
	days: '',
	pack: 0,
};

const initialDeleteFormValues = {
	iccid: ''
};

const initialResp = {
	activation: {
		type: 'danger',
		message: ''
	},
	delete: {
		type: 'danger',
		message: ''
	}
};

const Lanka = () => {
	const [packs, setPacks] = useState([]);
	const [saveBtnIsDisabled, setSaveBtnIsDisabled] = useState(false);
	const [deleteBtnIsDisabled, setDeleteBtnIsDisabled] = useState(false);
	const [packSelected, setPackSelected] = useState(0);
	const [activationFormValues, setActivationFormValues] = useState(initialActivationFormValues);
	const [deleteFormValues, setDeleteFormValues] = useState(initialDeleteFormValues);
	const [isActivationAlertDisplaying, setIsActivationAlertDisplaying] = useState(false);
	const [isDeleteAlertDisplaying, setIsDeleteAlertDisplaying] = useState(false);
	const [resp, setResp] = useState(initialResp);

	useEffect(() => {
		fetch(packEndpoint)
			.then(res => res.json())
			.then(data => {
				setPacks(data);
				setPackSelected(data[0]);
				setActivationFormValues({ ...activationFormValues, pack: data[0].value });
			});
	}, []);

	const showActivationAlert = () => {
		setIsActivationAlertDisplaying(true);
		setTimeout(() => setIsActivationAlertDisplaying(false), 7000);
	};

	const showDeleteAlert = () => {
		setIsDeleteAlertDisplaying(true);
		setTimeout(() => setIsDeleteAlertDisplaying(false), 7000);
	};

	const onActivationChange = (e) => {
		if (e.target.name === 'pack') {
			setPackSelected(e.target.value);
		}
		setActivationFormValues({ ...activationFormValues, [e.target.name]: e.target.value });
	};

	const onDeleteChange = (e) => {
		setDeleteFormValues({ ...deleteFormValues, [e.target.name]: e.target.value });
	};

	return (
		<>
			<LayoutHeader>Lanka</LayoutHeader>
			<Card>
				<CardBody>
					<CardTitle tag="h5">
						Activación y Top up
					</CardTitle>
					<Form
						onSubmit={e => {
							setSaveBtnIsDisabled(true);
							const { iccid, days, gb, pack } = activationFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/ventamobile/activation/${iccid}/${gb}/${days}/${pack}`;
							fetch(endpoint)
								.then(res => res.json()
									.then(data => {
										setResp({
											...resp,
											activation: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: res.status !== 200 ? data.message : `Activation status: ${data.activation} | add bundle status: ${data.addBundle}`
											}
										});
										showActivationAlert();
										setSaveBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										activation: {
											type: 'danger',
											message: error.message
										}
									});
									showActivationAlert();
									setIsActivationAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationChange}
										name='days'
										placeholder='# Días'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onActivationChange}
										name='gb'
										placeholder='# Gigas'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										type='select'
										value={packSelected}
										name='pack'
										onChange={onActivationChange}
									>
										{
											Array.isArray(packs) && packs?.map((item, idx) => <option value={item.value} key={idx}>{item.key}</option>)
										}
									</Input>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='info' disabled={
											saveBtnIsDisabled || 
											!activationFormValues.iccid ||
											!activationFormValues.days ||
											!activationFormValues.gb ||
											!activationFormValues.pack
										} 
										title='Submit'
									>
										Activar y Top up
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{isActivationAlertDisplaying && <Alert color={resp.activation.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.activation.message)}
					</Alert>
					}
				</CardBody>
			</Card>

			<Card>
				<CardBody>
					<CardTitle tag="h5">
						Desactivar
					</CardTitle>
					<Form
						onSubmit={e => {
							setDeleteBtnIsDisabled(true);
							const { iccid } = deleteFormValues;
							e.preventDefault();
							const endpoint = `${process.env.REACT_APP_CUSTOMER_API_URL}/ventamobile/deletion/${iccid}`;
							fetch(endpoint)
								.then(res => res.json()
									.then(data => {
										setResp({
											...resp,
											delete: {
												type: res.status !== 200 ? 'danger' : 'success',
												message: res.status !== 200 ? data.message : `${iccid} desactive process: ${data.message}`
											}
										});
										showDeleteAlert();
										setDeleteBtnIsDisabled(false);
									}))
								.catch(error => {
									setResp({
										...resp,
										delete: {
											type: 'danger',
											message: error.message
										}
									});
									showDeleteAlert();
									setIsDeleteAlertDisplaying(false);
								});
						}}
					>
						<Row>
							<Col xs={12} md={2}>
								<FormGroup>
									<Input
										onChange={onDeleteChange}
										name='iccid'
										placeholder='# Iccid'
									/>
								</FormGroup>
							</Col>
							<Col xs={12} md={2}>
								<FormGroup>
									<Button color='danger' disabled={deleteBtnIsDisabled || !deleteFormValues.iccid} title='Submit' >
										Desactivar
									</Button>
								</FormGroup>
							</Col>
						</Row>
					</Form>
					{isDeleteAlertDisplaying && <Alert color={resp.delete.type} className='text-center animated fadeDown'>
						{JSON.stringify(resp.delete.message)}
					</Alert>
					}
				</CardBody>
			</Card>
		</>
	);
};

export default Lanka;
