import addDays from 'date-fns/addDays';
import differenceInDays from 'date-fns/differenceInDays';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';
import max from 'date-fns/max';
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Col, Form, FormGroup, FormText, Input, Label, Row } from 'reactstrap';
import { getLocaleDate } from '../../helpers/activationDateHelpers';
import { useQuery } from '@apollo/react-hooks';
import { CARRIERS_SELECT_SCHEMA } from '../../constants';
import { generateSelectDataStructure } from '../../services/activaciones';
import gql from 'graphql-tag';

const GetProviders = gql`
	query {
		Providers{
			provider,
			carrier
		}
	}
`;

const FormEditActivations = ({ item, data, onChange }) => {
	const initialProvidersDataState = {
		data: [],
		// providerOptions: [],
		carrierOptions: []
	};

	const [providersData, setProvidersData] = useState(initialProvidersDataState);

	const { data: providersQuery } = useQuery(GetProviders);

	useEffect(() => {
		if (providersQuery) {
			// const providerList = providersQuery?.Providers.map(item => item.provider);
			const carrierList = providersQuery?.Providers
				.map(item => item.carrier)
				.flat()
				.filter(carrier => carrier !== '');

			// const providersProcessedOptions = generateSelectDataStructure(providerList, PROVIDERS_SELECT_SCHEMA);
			const carriersProcessedOptions = generateSelectDataStructure(carrierList, CARRIERS_SELECT_SCHEMA);

			setProvidersData({
				// providerOptions: providersProcessedOptions,
				carrierOptions: carriersProcessedOptions,
				data: providersQuery.Providers
			});
		}
	}, [providersQuery]);

	return (
		<Form>
			{item.deleted_at && <p className='alert alert-danger text-center'>Esta tarjeta está cancelada.</p>}
			{data.activable && !item.deleted_at && (
				<React.Fragment>
					<FormGroup inline>
						<Label>Iccid:</Label>
						<Input
							label='Iccid'
							onChange={e => onChange({ iccid: e.target.value.replace(/[^0-9][^F$]/g, '') })}
							value={data.iccid || ''}
							disabled={!!item.deleted_at}
						/>
					</FormGroup>
					<FormGroup>
						<Row className='align-items-center'>
							<Col xs='12' sm='6' className='align-middle'>
								<Label className='m-0'>Fecha de Activación:</Label>
							</Col>
							<Col xs='12' sm='6' className='align-middle'>
								<DatePicker
									id={data.id}
									name={data.id}
									className='text-center form-control b-0 ml-auto d-block w-100'
									type='date'
									dateFormat='dd/MM/yyyy'
									placeholder='Fecha de Activación'
									minDate={new Date()}
									selected={getLocaleDate(data.active_at) || null}
									disabled={!!item.deleted_at}
									onChange={active_at =>
										onChange({
											active_at: active_at && active_at !== '' ? max([active_at, new Date()]) : '',
											active_until:
												active_at && active_at !== '' ? addDays(active_at, item.days - 1) : '',
										})
									}
								/>
							</Col>
						</Row>
						<Row className='align-items-center mt-2'>
							<Col xs='12' sm='6'>
								<Label className='m-0'>Fecha de Fin de Activación:</Label>
							</Col>
							<Col xs='12' sm='6'>
								<DatePicker
									className='text-center form-control b-0 ml-auto d-block w-100'
									type='date'
									dateFormat='dd/MM/yyyy'
									placeholder='Fecha de Fin de Activación'
									minDate={data.active_at ? data.active_at : new Date()}
									maxDate={data.active_at ? addDays(data.active_at, item.days - 1) : null}
									selected={data.active_at && data.active_until ? getLocaleDate(data.active_until) : null}
									disabled={!data.active_at}
									name={data.id}
									id={data.id}
									onChange={active_until => onChange({ active_until })}
								/>
							</Col>
						</Row>
					</FormGroup>
					<Row>
						{data.iccid && data.iccid.endsWith('F') && (
							<Col md='6' className='animated fadeIn'>
								<FormGroup>
									<Label>Carrier</Label>
									<Input
										type='select'
										value={data.carrier}
										onChange={e => onChange({ carrier: e.target.value })}
										disabled={!!item.deleted_at}
									>
										{
											providersData?.carrierOptions.map((item, idx) => <option value={item.value} key={idx}>{item.name}</option>)
										}
									</Input>
								</FormGroup>
							</Col>
						)}
					</Row>
					{data.active_at && data.active_at !== '' && (
						<FormText className='text-center'>
							Funcionará del <strong>{format(data.active_at, 'dd/MM/yyyy')}</strong> al{' '}
							<strong>{format(data.active_until, 'dd/MM/yyyy')}</strong> (
							{differenceInDays(data.active_until, data.active_at) + 1} días).
						</FormText>
					)}
					<hr />
					<Row>
						<Col md='6'>
							<FormGroup check>
								<Label check>
									<Input
										type='checkbox'
										checked={data.revision}
										value={data.revision}
										onChange={() => onChange({ revision: !data.revision })}
									/>
									Pendiente de Revisión
								</Label>
								<FormText>Marca esta casilla si esta tarjeta necesita ser revisada más adelante.</FormText>
							</FormGroup>
						</Col>
						<Col md='6'>
							<FormGroup check>
								<Label check>
									<Input
										type='checkbox'
										checked={data.activation_requested}
										value={data.activation_requested}
										onChange={() => onChange({ activation_requested: !data.activation_requested })}
										disabled={item.activation_requested && !isAfter(item.active_at, new Date())}
									/>
									Esta tarjeta está activada
								</Label>
								<FormText>
									Marca esta casilla si has activado la tarjeta en un panel de un proveedor.
								</FormText>
							</FormGroup>
						</Col>
					</Row>
					<hr />
				</React.Fragment>
			)}
			<FormGroup>
				<Label>Notas</Label>
				<Input type='textarea' value={data.comments} onChange={e => onChange({ comments: e.target.value })} />
			</FormGroup>
		</Form>
	);
};


export default FormEditActivations;
