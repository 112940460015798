import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import Loading from './Loading';

const QueriableView = ({ query, whileLoading, haltOnErrors = true, children, ...props }) => {
	const { loading, error, data } = useQuery(query, { ...props });

	if (loading) {
		return <Loading text={whileLoading} />;
	}

	if (error && haltOnErrors) {
		return <div className='alert alert-danger text-center'>{error.message}</div>;
	}

	return children({ data, error });
};

export default QueriableView;
